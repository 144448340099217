<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            valid_mail: false,
            errors: {},
            submitted: false,
            redirec: false
        }
    },
    methods: {
        async triggerLogin() {
            this.submitted = true;
            try {
                let login = await this.loginAction(this.form)
                if (login) {
                    if (this.redirec) {
                        this.submitted = false;
                        Event.$emit('event-reload-loading');
                    } else {
                        this.submitted = false;
                        this.$nextTick(() => {
                            location.reload();
                        });
                    }

                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.submitted = false;
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        ...mapActions({
            loginAction: 'auth/login',
            errorNotification: 'alert/error',
        })
    },
    created() {
        Event.$on("event-show-login", (redirec = false) => {
            this.redirec = redirec;
            this.$modal.toggle('ModalLogin');
        });
    },
}
</script>
<template>
    <modal name="ModalLogin" :width="'95%'" :maxWidth="500" :adaptive="true" :shiftY="0.2">
        <div class="row">
            <div class="col-12 text-center head-modal">
                <img class="img-fluid pt-5" src="@/assets/img/logo_rojo.png" style="max-width: 200px;">
            </div>
            <div class="col-12 pl-5 pr-5 pb-5 pt-4">
                <div class="row">
                    <div class="col-12">
                        <h3>Inicie sesión </h3>
                        <hr>
                    </div>
                    <div class="col-12">
                        <form @submit.prevent="triggerLogin" class="mt-3">
                            <div class="col-12">
                                <input type="email" v-model="form.email" class="form-control" required
                                    placeholder="Correo electrónico">
                                <div v-if="errors && errors.email" class="text-danger mt-1">{{ errors.email[0] }}</div>
                            </div>
                            <div class="col-12 mt-3">
                                <input type="password" v-model="form.password" class="form-control" required
                                    placeholder="Contraseña">
                                <div v-if="errors && errors.password" class="text-danger">{{ errors.password[0] }}</div>
                            </div>
                            <div class="col-12 mt-4">
                                <div class="row">
                                    <div class="col-8 text-left">
                                        <router-link to="/reset" class="btn btn-link text-danger">Recuperar
                                            contraseña</router-link>
                                    </div>
                                    <div class="col-4 text-right">
                                        <button class="btn btn-block btn-danger br20 shaw20" :disabled="submitted">
                                            <span v-if="submitted">
                                                <i class="fa-solid fa-spinner fa-spin-pulse mr-1"></i> Enviando
                                            </span>
                                            <span v-else>
                                                <i class="fas fa-save"></i> Ingresar
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<style>
.head-modal {
    background-image: url('@/assets/img/fondo_01.jpg');
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>