<script>
/* global google */
export default {
    props: {
        center: {
            type: Object,
            required: true,
        },
        zoom: {
            type: Number,
            default: 13,
        },
    },
    data() {
        return {
            mapId: `google-map-${Math.random().toString(36).substring(7)}`,
        }
    },
    mounted() {
        const mapOptions = {
            // center: { lat: 37.7749, lng: -122.4194 }, // Coordenadas de San Francisco
            center: this.center, // Coordenadas de San Francisco
            zoom: this.zoom,
        };

        if (typeof google !== 'undefined') {

            const map = new google.maps.Map(document.getElementById(this.mapId), mapOptions);

            const markerOptions = {
                position: this.center, // Coordenadas donde se ubicará el marcador
                map: map, // El mapa en el que se mostrará el marcador
                icon: require('@/assets/img/pin.png')
            };

            const marker = new google.maps.Marker(markerOptions);
            console.log(marker);

        }

    }
}
</script>
<template>
    <div>
        <div :id="mapId" style="width: 100%; height: 400px;"></div>
    </div>
</template>