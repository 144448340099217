<script>
import ReserveUpdateComponent from './ReserveUpdateComponent.vue';
export default {
    components:{
        ReserveUpdateComponent
    },
    data() {
        return {
            booking: null,
            load: false
        }
    },
    methods: {
        closed() {
            this.load = true;
            this.booking = null;
            this.$modal.toggle('ModalBookingUpdate');
        },
    },
    created() {
        Event.$on("event-show-booking-update", (booking) => {
            this.booking = booking;
            this.$modal.toggle('ModalBookingUpdate');
        });
        Event.$on("event-close-booking-update", () => {
            this.closed()
        });
    },
}
</script>
<template>
    <modal name="ModalBookingUpdate" :width="'95%'" :maxWidth="700" :adaptive="true" :shiftY="0.2">
        <div class="row">
            <div class="col-12 col-lg-12 pl-5 pr-5 pt-4">
                <div class="row" v-if="booking">
                    <div class="col-12">
                        <button @click="closed()" class="btn float-right btn-sm rounded-r btn-danger">
                            <i class="fa fa-times"></i>
                        </button>
                        <h3>Editar de la reserva </h3>
                        <hr>
                    </div>
                    <div class="col-md-6 mt-2 col-12">
                        <h5 class="text-danger"># Reserva: {{ booking.reservation_number }}</h5>
                    </div>
                    <!-- <div class="col-md-6 mt-2 col-12 text-right" v-if="booking.status == 1">
                        <button class="btn btn-wath pr-3" @click="captureAndShare(booking)">
                            <i class="fa-brands fa-whatsapp mr-1 ml-1"></i>
                            Compartir
                        </button>
                    </div>
                    <div class="col-md-12 mt-2 col-12">
                        <h6>Fecha :</h6>
                        <span v-if="booking.date == booking.date_departure">
                            {{ booking.date | toDateTime }}
                        </span>
                        <span v-else>
                            {{ booking.date | toDateTime }}
                            <span v-if="booking.date_departure"> - {{ booking.date_departure | toDateTime }} </span>
                        </span>
                    </div>
 -->
                    <div class="col-md-6 mt-2 col-12">
                        <h6>Sala :</h6>
                        {{ booking.room.name }}
                    </div>

                    <!-- <div class="col-md-6 mt-2 col-12">
                        <h6>Horario :</h6>
                        {{ booking.time_start }} - {{ booking.time_end }}
                    </div>
                    <div class="col-md-6 mt-2 col-12" v-if="booking.user">
                        <h6>Código :</h6>
                        {{ booking.user.code_user }}
                    </div>
                    <div class="col-md-6 mt-2 col-12" v-if="booking.user">
                        <h6>Nombre :</h6>
                        <span v-if="booking.user.full_name">
                            {{ booking.user.full_name }}
                        </span>
                        <span v-else>
                            {{ booking.user.first_name }} {{ booking.user.last_name }}
                        </span>
                    </div> -->
                    <!-- <div class="col-md-6 mt-2 col-12" v-if="booking.user">
                        <h6>Email :</h6>
                        {{ booking.user.email }}
                    </div>
                    <div class="col-md-6 mt-2 col-12">
                        <h6>Participantes :</h6>
                        {{ booking.participants }} Asistente<span v-if="booking.participants > 1">s</span>
                    </div> -->
                    <!-- <div class="col-md-6 mt-2 col-12">
                        <h6>UDN :</h6>
                        {{ booking.udn ? booking.udn.name : '---' }}
                    </div> -->
                    <!-- <div class="col-md-6 mt-2 col-12">
                        <h6>Curso :</h6>
                        {{ booking.course ? booking.course.name : '---' }} {{ booking.course_other ? ' - ' +
                            booking.course_other : '' }}
                    </div>
                    <div class="col-md-6 mt-2 col-12">
                        <h6>Temas :</h6>
                        {{ booking.topic }}
                    </div> -->
                    <!-- <div class="mt-2 col-12">
                        <h6 class=" pb-2">Amenidades de la sala :</h6>
                        <div class="row">
                            <div class="col-md-4 pt-3 pb-3 col-6 text-center"
                                v-for="(amenitie, index_amenitie) in booking.room.amenities" :key="index_amenitie">
                                <i class="fa-2xl pb-3" :class="amenitie.preference.icon"></i><br>
                                <span style="font-size: 14px;">{{ amenitie.preference.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-center mt-2 col-12">
                        <hr>
                        <button @click="closed()" style="width: 200px;" class="btn rounded-r btn-danger">
                            Cerrar
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="col-12 col-lg-12 p-4">
                <ReserveUpdateComponent  :current_model="booking"/>
            </div>
        </div>
    </modal>
</template>
<style>
.head-modal {
    background-image: url('@/assets/img/fondo_01.jpg');
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.rounded-r {
    border-radius: 20px !important;
}</style>