import CryptoJS from "crypto-js";

const bs = 16;
const key = "ulx&T@u5d!nnQwvA";

export default {
  AESDecrypt(token) {
    token = token.replaceAll(" ", "+");
    const keyArray = CryptoJS.enc.Utf8.parse(key);
    const tokenArray = CryptoJS.enc.Base64.parse(token);
    const iv = CryptoJS.lib.WordArray.create(tokenArray.words.slice(0, bs / 4));
    const encryptedData = CryptoJS.lib.WordArray.create(
      tokenArray.words.slice(bs / 4)
    );
    console.log("encryptedData: ", encryptedData.toString());

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedData },
      keyArray,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
  },
};
