<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import request from '../services/request'
import BookingModal from '@/components/BookingModalComponent.vue'
import InventoryModal from '@/components/InventoryModalComponent.vue';
import pagination from '@/components/CustomPagination.vue'

export default {
    components: {
        BookingModal,
        InventoryModal,
        pagination
    },
    data() {
        return {
            for_page: 25,
            status: 1,
            room_id: '',
            send: false,
            sending: false,
            booking: {
                data: []
            },
            rooms: [],
        }
    },
    methods: {
        async get_booking(page = 1) {
            this.booking.data = [];
            this.sending = true;
            let service = request;
            let status = this.status;
            let for_page = this.for_page;
            let room_id = this.room_id;
            let _endpoint = `/booking/list_lider?room_id=${room_id}&page=${page}&status=${status}&n=${for_page}`;
            try {
                let { data } = await service.get(_endpoint);
                if (data) {
                    this.booking = data.data;
                    this.sending = false;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async getRooms() {
            let service = request;
            let _endpoint = `/booking/list_room`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.rooms = data.data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        showBooking(booking) {
            Event.$emit('event-show-booking', booking);
        },
        showInvetory(booking = {}){
            Event.$emit('event-show-inventory', booking);
        },
        async cancelBooking(id) {
            let vm = this;
            const { value: text } = await this.$swal.fire({
                input: 'textarea',
                inputLabel: 'Describe los motivos de cancelación',
                allowOutsideClick: false,
                inputPlaceholder: 'Escribe el motivo de la cancelación ...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value !== '') {
                            resolve()
                        } else {
                            resolve('Es requerido el motivo de la cancelación')
                        }
                    })
                },
                preConfirm: async (text) => {
                    let service = request;
                    let form = {
                        reason: text,
                        status: 0,
                    }
                    let _endpoint = `/booking/status_lider/${id}`;
                    try {
                        let { data } = await service.post(_endpoint, form)
                        if (data) {
                            vm.errors = await this.successNotification('Éxito al cancelar la reservación');
                            vm.get_booking();
                        }
                    } catch (error) {
                        this.errors = await this.errorNotification(error)
                    }
                },
            })
            console.log(text);
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },
    async mounted() {
        this.setHeaderTitle(`Administración de Reservas de mis salas`);
        if (this.authUser.admin) {
            await this.get_booking();
            await this.getRooms();
        } else {
            this.$router.push({ name: 'home' })
        }
    },
    watch: {
        'status'() {
            this.get_booking();
        },
        'for_page'() {
            this.get_booking();
        },
        'room_id'() {
            this.get_booking();
        }
    }
}
</script>
<template>
    <div class="row m-0 ">
        <div class="container pt-4">
            <div class="col-12 mt-5">
                <div class="card br20 shaw20">
                    <div class="card-body">
                        <div class="row bg-dark br20 text-white p-2">
                            <div class="col-12 col-md-12">
                                <select v-model="room_id" v-if="rooms.length > 1" class="form-control bg-dark">
                                    <option value="">Todas</option>
                                    <option v-for="(room, index_room) in rooms" :value="room.id" :key="index_room">{{
                                        room.name }}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6  b-r-md">
                                <select v-model="status" class="form-control bg-dark">
                                    <option value="1">Reserva Activa</option>
                                    <option value="2">Reserva Terminada</option>
                                    <option value="0">Reserva Cancelada</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6">
                                <select v-model="for_page" class="form-control bg-dark">
                                    <option value="25">Mostrar 25</option>
                                    <option value="50">Mostrar 50</option>
                                    <option value="100">Mostrar 100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="card br20 shaw20">
                    <div class="card-body">
                        <div class="row">                           
                            <div class="col-12 table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th class="text-center">No.</th>
                                            <th class="text-center">Sala</th>
                                            <th class="text-center">Usuario</th>
                                            <th class="text-center">Fecha</th>
                                            <th class="text-center">Horario</th>
                                            <th class="text-center">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="sending">
                                            <td colspan="6" class="text-center p-4">
                                                <i class="fa-solid fa-circle-notch fa-spin text-muted fa-3x"></i>
                                                <h5 class="text-muted">Cargando</h5>
                                            </td>
                                        </tr>
                                        <tr v-show="booking.data.length > 0" v-for="(book, index_book) in booking.data"
                                            :key="index_book">
                                            <td class="text-center">
                                                {{ (booking.from) ? (booking.from + index_book) : null }}
                                            </td>
                                            <td class="text-center">
                                                {{ book.room.name }}
                                            </td>
                                            <td class="text-center" v-if="book.user">
                                                <span v-if="book.user.full_name">
                                                    {{ book.user.full_name }}
                                                </span>
                                                <span v-else>
                                                    {{ book.user.first_name }} {{ book.user.last_name }}
                                                </span>
                                            </td>
                                            <td class="text-center" v-else>
                                                Sin usuario
                                            </td>
                                            <td class="text-center">
                                                {{ book.date | toDateTime }}
                                            </td>
                                            <td class="text-center">
                                                {{ book.time_start }} - {{ book.time_end }}
                                            </td>
                                            <td class="text-center">
                                                <!-- <button type="button" v-if="status == 1" @click="showInvetory(book)"
                                                    class="btn m-1 btn-sm btn-info">
                                                    <i class="fa-solid fa-boxes-packing"></i> Inventario
                                                </button> -->
                                                <button type="button" @click="showBooking(book)"
                                                    class="btn m-1 btn-sm btn-secondary">
                                                    <i class="fa fa-eye"></i> Ver
                                                </button>
                                                <button type="button" v-if="status == 1" @click="cancelBooking(book.id)"
                                                    class="btn m-1 btn-sm btn-danger">
                                                    <i class="fa fa-times"></i> Cancelar
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="booking.data.length == 0 && !sending">
                                            <td colspan="6" class="text-center p-4">
                                                Sin resultados.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                                <pagination :pagination="booking" :limit="7" v-on:change-page="get_booking" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BookingModal />
        <InventoryModal/>
    </div>
</template>
<style scoped>
@media (min-width: 768px) {
    .b-r-md {
        border-right: 1px solid #fff;
    }
}

.bg-dark {
    border: none !important;
}
</style>
