<script>
import { mapActions, mapMutations } from 'vuex';
import carousel from 'vue-owl-carousel'
import request from '../services/request'

import galeryOffice from '@/components/GaleryOfficeComponent.vue'

export default {
    components: {
        carousel,
        galeryOffice,
    },
    data() {
        return {
            id:'',
            booking_id:'',
            office: {},
            comment: {
                id: '',
                comment: '',
                date: '',
                time: '',
                preferences: {},
                type_report: '',
                booking_id: '',
                room_id: '',
                images: []
            },
            imagesFiles: [],
            error: {
                score: false
            },
            baseFiles: this.$BaseUrlFiles,
            send: false,
            sending: false,
        }
    },
    methods: {
        delImg(pos) {
            this.imagesFiles.splice(pos, 1);
            this.comment.images.splice(pos, 1);
        },
        async checkQueryToken() {
                
            if (this.$route.query && Object.keys(this.$route.query).length > 0) {
                console.log(this.$route.query);
                let code = this.$route.query.code;
                let form = {
                    code : code
                };
                try {
                    let login = await this.authLoginWithTokenReport(form)
                console.log(login);
                } catch (error) {
                    let message = JSON.parse(error.request.response).message;
                    this.errorNotificationMessage(message);
                    this.$router.push({ name: "home" });

                    // this.errors = await this.errorNotification(error)
                    
                }
                
            } else {
                this.$router.push({ name: "home" });
            }
        },
        uploadFiles(event) {
            let file = event.target.files[0];
            let allowedImageTypes = ["image/jpeg", "image/gif", "image/png"];
            if (!allowedImageTypes.includes(file.type)) {
                this.$swal({
                    icon: 'error',
                    title: 'Error en el tipo de archivo',
                    text: 'Solo se acepta archivo tipo imagen',
                });
            } else {
                this.comment.images.push(event.target.files[0]);
                this.imagesFiles.push(URL.createObjectURL(event.target.files[0]));
            }

        },
        async validReportExist() {
            let service = request;
            let _endpoint = `/report/verify/${this.id}?type=${this.comment.type_report}`;
            try {
                let { data } = await service.get(_endpoint);
                if (data) {
                    this.sending = data.data.report;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async get_office() {
            let service = request;
            let mv = this;
            let _endpoint = `/booking/list/${this.id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {                   
                    this.booking_id = data.data.id;
                    this.office = data.data.room;
                    this.comment.room_id = this.office.id;
                    this.comment.booking_id = data.data.id;
                    this.office.amenities.forEach(function (valor) {
                        let amenities = {
                            'type': '',
                            'comment': ''
                        };
                        mv.comment.preferences[valor.preference.name] = amenities;
                    });
                   
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async sendComment() {
            let vm = this;
            this.send = true;

            this.$swal({
                title: 'Enviar comentarios',
                text: 'No podrás editar el comentario',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#5c5c5c',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result) {
                        try {
                            let service = request;
                            let _endpoint = `/report/save/${this.booking_id}`
                            let dataForm = new FormData();
                            dataForm.append('comment', this.comment.comment);
                            dataForm.append('date', this.comment.date);
                            dataForm.append('time', this.comment.time);
                            dataForm.append('preferences', JSON.stringify(this.comment.preferences));
                            dataForm.append('type_report', this.comment.type_report);
                            dataForm.append('booking_id', this.comment.booking_id);
                            dataForm.append('room_id', this.comment.room_id);
                            this.comment.images.forEach(function (valor, index) {
                                console.log(index);
                                console.log(valor);
                                dataForm.append('images[' + index + ']', valor);
                            });

                            const today = vm.moment();
                            dataForm.append('date', today.format('YYYY-MM-DD'));
                            dataForm.append('time', today.format('HH:mm'));

                            let { data } = await service.postMedia(_endpoint, dataForm)
                            if (data) {
                                this.send = false;
                                await this.successNotification('Éxito al guardar el comentario.');
                                this.$router.push({ name: 'home' });

                            }
                        } catch (error) {
                            this.send = false;
                            this.errors = await this.errorNotification(error)
                        }
                    }
                } else {
                    this.send = false;
                }
            });
        },
        onChange() {
            this.$forceUpdate();
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            errorNotificationMessage: 'alert/errorMessage',
            successNotification: 'alert/success',
            authLoginWithTokenReport: "auth/authLoginWithTokenReport",
        }),
    },
    async mounted() {
        this.setHeaderTitle(`Reporte`);
        this.id = this.$route.params.id;
        this.comment.type_report = this.$route.query.type;
        this.checkQueryToken();
        if (this.id) {
            await this.get_office();
            await this.validReportExist();
        }
    },
    watch: {
        comment(newValue) {
            console.log(newValue);
            this.$forceUpdate();
        }
    },
}
</script>
<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5 mb-5">
                <div class="row">
                    <div class="col-12">
                        <h2>{{ office.name }}</h2>
                        <p class="card-text mt-2 mb-4 uppercase" style="font-size: 16px;">
                            {{ office.address }}
                        </p>

                    </div>
                </div>

                <!-- Cards -->
                <div class="row">
                    <div class="col-md-12">

                        <!-- Cards -->
                        <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                            <div class="row">
                                <div class="col-12">
                                    <galeryOffice :media="office.medias" />
                                </div>
                            </div>
                        </div>

                        <!-- CARROUCEL -->
                        <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                            <div class="row">
                                <div class="col-12">
                                    <carousel :autoplay="true" v-if="office.medias" :nav="true" :loop="true" :items="1">
                                        <img v-for="(media, index_media) in office.medias" :key="index_media"
                                            :src="`${baseFiles}${media.media.file_path}${media.media.file_name}`">
                                    </carousel>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-12">
                        <div classs="row">
                            <div class="col-12 mt-4 mb-4 text-center">
                                <hr>
                            </div>
                            <form @submit.prevent="sendComment" v-if="sending == false">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <h4>Gracias por contribuir con la comunidad</h4>
                                            <p> Día a día, miles de personas leen opiniones antes de decidir su reserva.</p>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <h5>Comentarios generales</h5>
                                            <textarea rows="3" :readonly="comment.id != ''" required
                                                v-model="comment.comment" class="form-control"></textarea>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <h5>Califica las amenidades que tiene:</h5>
                                        </div>
                                        <div class="col-12 mt-3" v-for="(amenitie, index_amenitie) in office.amenities"
                                            :key="index_amenitie">
                                            <div class="row brd-secondary">
                                                <div class="col-md-6 col-12 text-center">
                                                    <h5 class="mt-3">{{ amenitie.preference.name }}</h5>
                                                </div>
                                                <div class="col-md-3 col-6 text-center">
                                                    <label>
                                                        <input :name="index_amenitie" class="form-control" required
                                                            v-model="comment.preferences[amenitie.preference.name]['type']"
                                                            @change="onChange($event)" type="radio" :value="'bien'">
                                                        Bien
                                                    </label>
                                                </div>
                                                <div class="col-md-3 col-6 text-center">
                                                    <label>
                                                        <input :name="index_amenitie" class="form-control" required
                                                            v-model="comment.preferences[amenitie.preference.name]['type']"
                                                            @change="onChange($event)" type="radio" :value="'mal'">
                                                        Mal
                                                    </label>
                                                </div>
                                                <div class="col-md-12 col-12"
                                                    v-if="comment.preferences[amenitie.preference.name]['type'] == 'mal'">
                                                    <hr>
                                                    <textarea class="form-control" row="3" required
                                                        @keyup="onChange($event)"
                                                        v-model="comment.preferences[amenitie.preference.name]['comment']"
                                                        placeholder="Comenta que esta mal"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <hr>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="card-body text-center">
                                                    <h3>Comparte fotos de evidencia.</h3>
                                                    <p>Máximo 4 fotos.</p>
                                                    <div class="row mt-5 mb-5">
                                                        <div class="col-12" v-if="comment.images.length == 0">
                                                            <i class="fa-solid fa-camera fa-5x"></i>
                                                            <h3>Sin evidencia adjunta</h3>
                                                        </div>
                                                        <div class="col-md-3 col-12 p-2"
                                                            v-for="(img, index_img) in imagesFiles" :key="index_img"
                                                            v-show="comment.images.length > 0">
                                                            <div class="card shadow">
                                                                <button class="btn btn-sm btn-danger del" type="button"
                                                                    @click="delImg(index_img)"><i
                                                                        class="fa fa-trash"></i></button>
                                                                <div class="card-body text-center">
                                                                    <img class="img-fluid rounded" :src="img">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="image-upload btn btn-outline-secondary btn-lg"
                                                        v-if="comment.images.length <= 3">
                                                        <label for="file-input">
                                                            <h4 class="m-0"> <i class="fa-solid fa-camera fa-1x mr-2"></i>
                                                                Subir
                                                                Imagen</h4>
                                                        </label>
                                                        <input id="file-input" accept="image/*" type="file"
                                                            @change="uploadFiles" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <button v-if="comment.id == ''" :disabled="send"
                                        class="btn btn-lg btn-danger float-right pl-5 pr-5" style="border-radius: 30px;">
                                        <i v-if="send" class="fa-solid fa-circle-notch fa-spin mr-2"></i>
                                        <span v-if="send">Enviando</span>
                                        <span v-else>Enviar comentarios</span>
                                    </button>
                                </div>
                            </form>
                            <div class="row" v-else>

                                <div class="col-12 text-center p-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <i class="fa-regular fa-circle-check text-red fa-5x"></i>
                                            <h3 class="mt-4 text-secondary"> Gracias por contribuir a la mejora de nuestras
                                                <br> salas de
                                                experiencias
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<style scoped>
.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 150px;
}

.rounded-lg {
    border-radius: 20px !important;
}

.hr_head {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 42px;
    position: relative;
}

.hr_footer {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 282px;
    position: relative;
}

.shadow_cal {
    box-shadow: rgba(136, 165, 191, 0.48) 5px 6px 6px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.swal2-icon.swal2-success.swal2-icon-show {
    margin-top: 120px !important;
}

.img.swal2-image {
    position: absolute;
    left: 32% !important;
}

.brd-secondary {
    margin: 1px;
    border: 2px solid #ddd;
    padding: 15px 10px 10px 10px;
    border-radius: 5px;
}

input[type='radio'] {
    accent-color: #da271b;
}

.image-upload>input {
    display: none;
}

@media (max-width: 575px) {
    .rounded {
        height: auto;
    }
}

.rounded {
    height: 150px;
    text-transform: capitalize;
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.del {
    position: absolute;
    right: 0px;
    margin: 10px;
}
</style>
