<script>
import { mapActions } from 'vuex';
import request from '../services/request'
import VueNumericInput from 'vue-numeric-input'
export default {
    components: {
        VueNumericInput
    },
    data() {
        return {
            form: {
                room_id: '',
                booking_id: '',
                assistants: '',
            },
            assistants: 0,
            items: [],
            room_id: '',
            stockForm: [],
            booking: {},
            load: false,
        }
    },
    methods: {
        closedModal() {
            this.form.booking_id = '';
            this.form.assistants = '';
            // this.form.stock = [];
            // this.stockForm = [];
            this.$modal.hide('ModalStock');
        },
        /* existValuePosition(id) {
            let stock = this.items;
            const position = stock.findIndex((element) => element.id === id);
            return position;
        }, */
        async sendForm() {
            let vm = this;

            this.$swal({
                title: 'Finalizar reserva',
                text: `Seguro que asistieron ${this.form.assistants} usuarios`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#5c5c5c',
                confirmButtonText: 'Si',
                cancelButtonText: 'No, cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    let service = request;
                    vm.form.booking_id = vm.booking.id;
                    // vm.form.stock = vm.stockForm;
                    let form = vm.form;
                    let _endpoint = `/booking/inventory_booking`;
                    try {
                        let { data } = await service.post(_endpoint, form)
                        if (data) {
                            /* await vm.successNotification('Se guardó la información correctamente.');
                            vm.closedModal();
                            console.log(data); */
                            vm.$swal({
                                html:
                                    '<div class="confirmation">' +
                                    '<img class="mt-4" width="180" src="' + require('@/assets/img/logo_rojo.png') + '"> <br> ' +
                                    '<img width="180" class="mt-4" src="' + require('@/assets/img/check.png') + '"> <br> ' +
                                    '<h3 class="mt-4">Finalización exitosa</h3>' +
                                    '<p class="notifyModal text-center"><strong>Tu reserva #</strong> <strong class="codeModal">' + vm.booking.reservation_number + '</strong> se ha finalizado con éxito y actualizado el inventario. </p>' +
                                    '<p class="notifyModal text-center">Gracias por hacer uso del Módulo de Reservas </p>' +
                                    '<p class="notifyModal text-center">¿Puedes compartir tus comentarios sobre tu experiencia con nosotros? Nos encantaría recibir tu opinión sobre tu reserva </p>' +
                                    '</div>' +
                                    // '<button class="shareBtn">Compartir en WhatsApp</button>',
                                    '',
                                background: '#f9f9f9',
                                confirmButtonColor: '#da281b',
                                confirmButtonText: 'Cerrar',

                            });
                            vm.closedModal();
                            setTimeout(() => {
                                vm.$router.push({ path: 'reviews/' + vm.booking.id });
                            }, 1500);
                        }
                    } catch (error) {
                        vm.errors = await this.errorNotification(error)
                    }

                }
            });
            return false;


        },
        /* async getStock() {
            let vm = this;
            let service = request;
            let room_id = this.room_id;
            let _endpoint = `/booking/inventory/${room_id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data.data);
                    vm.items = data.data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async dataFormat() {
            let vm = this;
            this.items.forEach(function (item) {
                let _stock = {
                    item_id: item.id,
                    used: 0
                };
                vm.stockForm.push(_stock);
            });
        }, */
        /* async getStockUsed() {
            let booking_id = this.booking.id;
            let service = request;
            let vm = this;
            let _endpoint = `/booking/inventory_used/${booking_id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    let stock = data.data;
                    stock.forEach(function (valor) {
                        let position = vm.existValuePosition(valor.inventory_id);
                        vm.stockForm[position].used = parseInt(valor.quantity);

                    });
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async getCourseInventory() {
            let course_id = this.booking.course_id;
            let participants = this.booking.participants;
            let service = request;
            let vm = this;
            let _endpoint = `/booking/inventory_course/${course_id}`;
            try {
                let { data } = await service.get(_endpoint, { 'room_id': this.booking.room_id })
                if (data) {
                    let stock = data.data;
                    console.log(stock);
                    stock.forEach(function (valor) {
                        let position = vm.existValuePosition(valor.inventory_id);
                        vm.stockForm[position].used = parseInt(participants);

                    });
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        }, */
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-inventory", async (booking) => {
            this.load = true;
            this.$modal.show('ModalStock');
            if (booking) {
                this.booking = booking;
                this.form.assistants = this.booking.participants;
                this.form.room_id = this.booking.room_id;
                // console.log(this.booking.participants);
                this.booking_id = booking.id;
                this.room_id = booking.room_id;

                // await this.getStock();
                // await this.dataFormat();
                // await this.getStockUsed();
                // await this.getCourseInventory();
                this.load = false;

            }
        });
    },
}
</script>
<template>
    <modal name="ModalStock" v-bind:click-to-close="false" :width="'95%'" :maxWidth="500" :adaptive="true"
        :shiftY="0.1">

        <div style="background-color: #343a40;">
            <h5 class="p-3 text-white">
                Confirmar asistencia
                <br v-if="booking.room"><span v-if="booking.room" style="font-size: 14px;">
                </span>
                <button class="btn btn-sm btn-danger btn-closed-modal mt-2 float-right br20" @click="closedModal">
                    <i class="fa-solid fa-x"></i>
                </button>
            </h5>
        </div>
        <div class="row" v-if="load">
            <div class="col-12 text-center p-5">
                <i class="fa-solid fa-circle-notch mb-3 fa-spin text-muted fa-5x"></i>
                <h3 class="text-muted">Cargando</h3>
            </div>
        </div>
        <div v-else class="row p-3">
            <div class="col-12" v-if="booking.room" >
                <div class="row mt-2">

                    <div class="col-12">
                        <h4 role="button" @click="redirectOffice(booking.room.id)" class="card-title tit">{{
        booking.room.name }}</h4>
                        <p class="card-text ml-n2 mt-4 card_address text-muted col-10" style="font-size: 12px;">
                            <a @click="showMap(booking.room)" class="text-muted uppercase">
                                {{ booking.room.address }}
                            </a>
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-5">
                                <h5 class="text-success mb-2">Reservado</h5>
                            </div>
                            <div class="col-7 text-right">
                                <h6 class="text-danger" v-if="booking.reservation_number">#
                                    Reserva: {{ booking.reservation_number }}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-7 col-md-8">
                                <p style="font-size: 13px;" class="mt-n1 mb-1">
                                    <strong>Fecha:</strong><br>
                                    {{ moment(booking.date).format('DD/MM/YYYY') }}
                                    <span v-if="booking.date_departure">
                                        <span v-if="booking.date != booking.date_departure">
                                            a {{ moment(booking.date_departure).format('DD/MM/YYYY') }}
                                        </span>
                                    </span>

                                </p><br>
                                <p style="font-size: 13px;" class="mt-n4">
                                    <strong>Horario:</strong><br> {{
        booking.time_start.substring(0, 5) }}
                                    Hrs a {{ booking.time_end.substring(0, 5) }} Hrs
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <p style="font-size: 13px;" class="mt-n1 mb-1">
                                    <strong class="mb-2">Usuarios que asistieron:</strong><br>
                                    <vue-numeric-input v-model="form.assistants" :size="'normal'" :min="0"
                                        align="center"></vue-numeric-input>

                                </p>

                            </div>
                        </div>

                        <div class="col-12">
                            <hr>
                            <button class="btn btn-secondary btn-sm" @click="closedModal()">
                                <i class="fa-solid fa-rotate-left"></i> Regresar
                            </button>
                            <button type="button" @click="sendForm()" class="btn btn-danger btn-sm float-right">
                                <i class="fas fa-save"></i> Confirmar Uso y Finalizar Reserva
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<style>
.btn-closed-modal {
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>