<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Catalogue from '@/services/catalogue';
import request from '../services/request'
import Multiselect from 'vue-multiselect'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';

import pagination from '@/components/CustomPagination.vue'

import ModalMapComponent from '@/components/ModalMapComponent.vue';
import ModalMediaZoom from '@/components/ModalMediaZoom.vue';


export default {
    components: {
        Multiselect,
        DatePicker,
        pagination,
        ModalMapComponent,
        ModalMediaZoom
    },
    data() {
        return {
            catalogue: new Catalogue(),
            name: '',
            search: {
                type_room_id: '',
                date: '',
                time_start: '',
                time_end: '',
                assistants: '',
                division_id: '',
                territory_id: '',
                region_id: '',
                column: ''
            },
            baseFiles: this.$BaseUrlFiles,
            territory: '',
            division: '',
            region: '',
            load: true,
            load_all: true,
            lists: {
                data: {
                    data: []
                }
            },
            order_position: 1,
            favorites: [],
            errors: {},
            num_assistants: [],
            state: {
                disabledDates: {
                    to: new Date(),
                }
            },
            // MIN -  MAX
            min_assistants: 0,
            max_assistants: 0,
            min_hours: 0,
            max_hours: 0,
            deep: false,
            timesStars: '',
            timesEnd: '',
            momentFormat: {
                stringify: (date) => {
                    return date ? this.moment(date).format('dddd') + ' ' + this.moment(date).format('LL') : ''
                },
                parse: (value) => {
                    return value ? this.moment(value, 'DD-MM-YYYY').toDate() : ''
                }
            },
            load_modal: false,
            send_like: []
        }
    },
    methods: {
        disabledRange: function (date) {
            return date <= moment().subtract(1, 'd');
        },
        async loopAssistants() {
            if (this.catalogue.min_max_assistants) {
                let n = [];
                let min = this.catalogue.min_max_assistants[0].data.min_assistants;
                let max = this.catalogue.min_max_assistants[0].data.max_assistants;

                for (let index = min; index <= max; index++) {
                    n.push(index);
                }

                this.num_assistants = n;
            }

        },
        async list(page = 1) {
            this.load = true;
            let service = request;
            let search = {
                name: this.name,
                date: this.search.date[0],
                date_departure: this.search.date[1],
                time_start: this.search.time_start,
                time_end: this.search.time_end,
                assistants: this.search.assistants,
                division_id: this.search.division_id,
                territory_id: this.search.territory_id,
                region_id: this.search.region_id,
                column: this.search.column,
                type_room_id: this.search.type_room_id,
            };
            let _endpoint = '/booking/search';
            try {
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    this.lists = data;
                    this.load = false;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        showMap(office) {
            Event.$emit('event-map-office', office);
        },
        async save_favorite(id) {
            let vm = this;
            this.send_like.push(id);
            try {
                let form = {
                    id: id
                };
                let _endpoint = '/favorite/save';
                let service = request;

                let { data } = await service.post(_endpoint, form)
                if (data) {
                    this.get_favorite();
                    if (data.type == 'deleted') {
                        this.errors = await this.successNotification('Éxito al eliminar de tus favoritos')
                    } else {
                        this.errors = await this.successNotification('Éxito al agregar a favoritos');
                    }
                    vm.send_like = vm.send_like.filter(function (v) { return v !== id })
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
                vm.send_like = vm.send_like.filter(function (v) { return v !== id })
            }
        },
        async get_favorite() {
            try {
                let _endpoint = '/favorite/list';
                let service = request;
                let { data } = await service.get(_endpoint)
                if (data) {
                    let ids = data.map(x => x['room_id']);
                    this.favorites = ids;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        limitTextTerritorio(count) {
            return `y ${count} otros territorios`
        },
        limitTextRegion(count) {
            return `y ${count} otros Regiones`
        },
        asyncFindRegion(query = '') {
            try {
                let id = this.territory.id;
                this.catalogue.get_regions(query, id);
            } catch (error) {
                console.log(error);
            }
        },
        asyncFindTerritorio(query) {
            try {
                let id = this.division.id;
                this.catalogue.get_territories(query, id);
            } catch (error) {
                console.log(error);
            }
        },
        asyncFindDivision(query) {
            try {
                this.catalogue.get_divisions(query);
            } catch (error) {
                console.log(error);
            }
        },
        async catchParameters() {
            let params = JSON.parse(localStorage.getItem('filter_data'));
            if (params) {

                if (params.name) {
                    this.name = params.name;
                }

                if (params.date.length > 0) {
                    console.log(params.date);
                    // this.search.date = this.moment(params.date).toDate();
                    this.search.date = [this.moment(params.date[0]).toDate(), this.moment(params.date[1]).toDate()];
                }

                if (params.type_room_id) {
                    console.log('1');
                    this.search.type_room_id = params.type_room_id;
                }

                if (params.assistants) {
                    console.log('2');
                    this.search.assistants = params.assistants;
                }

                if (params.time_start) {
                    console.log('3');
                    this.search.time_start = params.time_start;
                }

                if (params.time_end) {
                    console.log('4');
                    this.search.time_end = params.time_end;
                }

                if (params.division_id != '') {
                    console.log('5');
                    await this.catalogue.get_territories('', params.division_id);
                    this.search.division_id = params.division_id;
                    let cat_divisions = await this.catalogue.divisions.data;
                    console.log(cat_divisions);
                    if (cat_divisions.length > 0) {
                        let usedDivision = await cat_divisions.find((element) => element.id == params.division_id);
                        this.division = usedDivision;
                    }
                }

                if (params.territory_id != '') {
                    console.log('6');
                    await this.catalogue.get_regions('', params.territory_id);
                    this.search.territory_id = params.territory_id;
                    let cat_territories = await this.catalogue.territories.data;
                    if (cat_territories) {
                        let usedTerritory = await cat_territories.find((element) => element.id == params.territory_id);
                        this.territory = usedTerritory;
                    }

                }

                if (params.region_id != '') {
                    console.log('7');
                    this.search.region_id = params.region_id;
                    let cat_regions = await this.catalogue.regions.data;
                    console.log(cat_regions);
                    if (cat_regions.length > 0) {
                        let usedRegion = await cat_regions.find((element) => element.id == params.region_id);
                        this.region = usedRegion;
                    }
                }
            }

            await this.list();
        },
        showFilter() {
            this.$modal.show('ModalFilter');
        },
        cancelFilter() {
            this.$modal.hide('ModalFilter');
        },
        getDays(days) {
            let arrayDays = JSON.parse(days);
            let daysSpanish = [];
            arrayDays.forEach(function (day) {
                if (day == 'monday') {
                    daysSpanish[0] = 'Lun';
                }
                if (day == 'tuesday') {
                    daysSpanish[1] = 'Mar';
                }
                if (day == 'wednesday') {
                    daysSpanish[2] = 'Mie';
                }
                if (day == 'thursday') {
                    daysSpanish[3] = 'Jue';
                }
                if (day == 'friday') {
                    daysSpanish[4] = 'Vie';
                }
                if (day == 'saturday') {
                    daysSpanish[5] = 'Sáb';
                }
                if (day == 'sunday') {
                    daysSpanish[6] = 'Dom';
                }
            });
            let text = '';
            let nDays = daysSpanish.length;
            nDays--;
            daysSpanish.forEach(function (day, key) {
                if (day) {
                    text += day;
                    if (nDays != key) {
                        text += ', ';
                    }
                }
            });
            return text;
        },
        exist_favorite(id) {
            let result = this.favorites.includes(id);     // true
            return result;
        },
        clarFilterModal() {
            this.search.type_room_id = '';
            this.search.time_start = '';
            this.search.time_end = '';
            this.search.territory_id = '';
            this.search.region_id = '';
            this.search.division_id = '';
            this.territory = '';
            this.region = '';
            this.division = '';

            this.$modal.hide('ModalFilter');
            this.list();
        },
        loaderTerritories(selectedItems) {
            this.region = '';
            this.asyncFindRegion();
            if (selectedItems) {
                this.asyncFindTerritorio();
                if (!this.load_all) {
                    this.territory = '';
                    this.search.division_id = selectedItems.id;
                    this.search.territory_id = '';
                    this.search.region_id = '';
                }
            } else {
                this.asyncFindTerritorio();
                if (!this.load_all) {
                    this.territory = '';
                    this.search.division_id = '';
                    this.search.territory_id = '';
                    this.search.region_id = '';
                }
            }
        },
        loaderRegions(selectedItems) {
            if (selectedItems) {
                this.asyncFindRegion();
                if (!this.load_all) {
                    this.region = '';
                    this.search.territory_id = selectedItems.id;
                    this.search.region_id = '';
                }
            } else {
                this.asyncFindRegion();
                if (!this.load_all) {

                    this.region = '';
                    this.search.territory_id = '';
                    this.search.region_id = '';
                }
            }
        },
        getRegionSelected(selectedItems) {
            if (selectedItems) {
                this.search.region_id = selectedItems.id;
            } else {
                this.search.region_id = '';
            }
        },
        redirectOffice(id) {
            let search = this.search;
            localStorage.setItem('filter_data', JSON.stringify(search));
            this.$router.push({ path: '/office/' + id })
        },
        getTimes(start = '09:00:00', end = '21:00:00', returnA = false) {
            let timesInBetween = [];
            let startH = parseInt(start.split(":")[0]);
            let startM = parseInt(start.split(":")[1]);
            let endH = parseInt(end.split(":")[0]);
            let endM = parseInt(end.split(":")[1]);

            if (startM == 30)
                startH++;
            for (let i = startH; i < endH; i++) {
                timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
                timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
            }
            timesInBetween.push(endH < 10 ? "0" + endH + ":00" : endH + ":00");
            if (endM == 30)
                timesInBetween.push(endH < 10 ? "0" + endH + ":30" : endH + ":30");

            if (returnA) {
                return timesInBetween;
            } else {
                timesInBetween.pop();
                this.timesStars = timesInBetween;
            }
        },
        catchRange(time) {
            let hours = this.getTimes(this.min_hours, this.max_hours, true);
            let prv = [];
            if (hours) {
                hours.forEach(timesU => {
                    if (time <= timesU) {
                        prv.push(timesU);
                    }
                });
            }
            this.timesEnd = prv;
        },
        formatHours(time) {
            return moment(time, "HHmm").format("hh:mm a");
        },
        difHours(time) {
            if (this.search.time_start) {
                var hora1 = time.split(":"),
                    hora2 = this.search.time_start.split(":"),
                    t1 = new Date(),
                    t2 = new Date();

                t1.setHours(hora1[0], hora1[1]);
                t2.setHours(hora2[0], hora2[1]);

                //Aquí hago la resta
                t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes(), t1.getSeconds() - t2.getSeconds());

                let h = (t1.getHours() ? t1.getHours() + (t1.getHours() > 1 ? " hrs" : " hr ") : "");
                let m = (t1.getMinutes() ? t1.getMinutes() + (t1.getMinutes() > 1 ? " min" : " min") : "");

                let text = '';
                if (h != "") {
                    text += h;
                    if (m != "") {
                        text += ' y ';
                    }
                }

                if (m != "") {
                    text += m;
                }

                if (text == '') {
                    text = '0 min'
                }

                return ' - (' + text + ')';
            } else {
                return '';
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        async catchMaxMin() {
            this.min_assistants = this.catalogue.min_max_assistants[0].data.min_assistants;
            this.max_assistants = this.catalogue.min_max_assistants[0].data.max_assistants;
            this.min_hours = this.catalogue.min_max_hours[0].data.min_time;
            this.max_hours = this.catalogue.min_max_hours[0].data.max_time;
            console.log(this.min_hours);
            console.log(this.max_hours);
            await this.getTimes(this.min_hours, this.max_hours);
            this.catchRange(this.search.time_start);
        },
        loadSerach() {
            let vm = this;
            this.load_modal = true;
            this.search.region_id = '';
            setTimeout(function () {
                vm.load_modal = false;
            }, 900);
        },
        zoomMedia(media) {
            console.log('csadclmdsaclkdmoi8989');
            console.log(media);
            Event.$emit('event-zoom-media', media);
        }
    },
    async mounted() {
        localStorage.removeItem('reserve_data');
        await this.catalogue.get_min_max_hours();
        await this.catalogue.get_type_rooms();
        await this.catalogue.get_divisions();
        await this.catalogue.get_territories();
        await this.catalogue.get_min_max_assistants();
        await this.catalogue.get_regions();
        await this.catalogue.get_num_assistants();
        await this.setHeaderTitle('Salas de Experiencia');
        await this.catchParameters();
        if (this.authUser) {
            await this.get_favorite();
        }
        await this.catchMaxMin();

        this.load_all = false;
        this.deep = true;
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },
    watch: {
        'search.time_start'(newValue) {
            console.log(newValue);
            if (newValue > this.search.time_end) {
                this.search.time_end = "";
            }
            if (newValue) {
                console.log(newValue);
                this.catchRange(newValue);
            }
        },
        // territory(newValue) {
        //     if (newValue) {
        //         this.asyncFindRegion();
        //         if (!this.load_all) {
        //             this.region = '';
        //             this.search.territory_id = newValue.id;
        //         }
        //     } else {
        //         this.asyncFindRegion();
        //         if (!this.load_all) {

        //             this.region = '';
        //             this.search.territory_id = '';
        //         }
        //     }
        // },
        // division(newValue) {
        //     if (newValue) {
        //         this.asyncFindTerritorio();
        //         if (!this.load_all) {

        //             this.territory = '';
        //             this.search.division_id = newValue.id;
        //         }
        //     } else {
        //         this.asyncFindTerritorio();
        //         if (!this.load_all) {

        //             this.territory = '';
        //             this.search.division_id = '';
        //         }
        //     }
        // },
        // region(newValue) {
        //     if (!this.load_all) {
        //         if (newValue) {
        //             this.search.region_id = newValue.id;
        //         } else {
        //             this.search.region_id = '';
        //         }
        //     }
        // },
        'search.assistants'(newValue) {
            let max = this.catalogue.min_max_assistants[0].data.max_assistants;
            if (max < newValue) {
                this.search.assistants = max;
            }
        },
        'search.type_room_id': function () {
            this.loadSerach();
        },
        'name': function (val) {
            if (val == '') {
                this.list();
            }
        },
        search: {
            deep: true,
            immediate: false,
            handler() {
                if (this.deep) {
                    console.log('filtro')
                    this.list();
                }

            }
        }
    }
}
</script>
<template>
    <div class="row m-0 ">
        <div class="container pt-4">

            <div class="col-12 mt-5">
                <div class="card br20 shaw20">
                    <div class="card-body">
                        <div class="row bg-dark br20 text-white p-2">
                            <div class="col-12 b-b-md b-b-xs">
                                <input class="form-control bg-dark" type="search" v-model="name" v-debounce:1s="list"
                                    :placeholder="search.type_room_id == 1 ? 'Buscar centro de experiencia ' : 'Buscar cuartel '">
                            </div>
                            <div class="col-12 col-md-8 col-lg-8 b-r-md b-b-xs">
                                <date-picker :range="true" v-model="search.date" :disabled-date="disabledRange"
                                    input-class="form-control bg-dark text-date" placeholder="Buscar por fecha"
                                    :formatter="momentFormat"></date-picker>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text border-dark bg-dark" for="inputGroupSelect01">
                                            <i class="fa-solid fa-users-gear "
                                                style="font-size: 23px; margin-top: 4px;"></i>
                                        </label>
                                    </div>
                                    <input v-if="!catalogue.min_max_assistants_load" type="number"
                                        placeholder="Núm. de participantes" class="form-control bg-dark"
                                        v-model="search.assistants" :min="min_assistants" :max="max_assistants">
                                    <div class="row text-center w-75" v-else>
                                        <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                            style="margin: 0 auto;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-8 ml-n2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text border-white bg-white" for="inputGroupSelect01">
                                            <i class="icon_res fa-solid fa-arrow-down-up-across-line"
                                                style="font-size: 20px; margin-top: 4px;"></i>
                                        </label>
                                    </div>
                                    <select v-model="search.column" @change="list"
                                        class="custom-select form-control-lg border-white bg-white "
                                        style="font-size: 20px; box-shadow:none;">
                                        <option value="1">Asistentes ascendentes</option>
                                        <option value="2">Asistentes descendentes</option>
                                        <option value="3">Nombre ascendentes</option>
                                        <option value="4">Nombre descendentes</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4 text-right">
                                <button @click="showFilter()" class="btn br20 shaw20 btn-outline-dark text-muted  mr-n3">
                                    <i class="icon_res mt-1 fa-solid fa-sliders"></i>
                                    <span class="ml-3 txt-h">Filtrar</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Filtro -->
            <modal name="ModalFilter" v-bind:click-to-close="false" :width="'95%'" :maxWidth="600" :adaptive="true"
                :shiftY="0.2">
                <div class="col-12 text-center mt-5 mb-5 pt-3" v-if="load_modal">
                    <i class="fa-solid fa-circle-notch mb-3 fa-spin text-muted fa-5x"></i>
                    <h3 class="text-muted">Cargando</h3>
                </div>
                <div v-else class="row p-4">
                    <div class="col-12">
                        <h4>Filtros</h4>
                        <hr>
                    </div>
                    <div class="col-12 mb-3">
                        <label>Tipo de espacio</label>
                        <div v-if="!catalogue.type_rooms_load" class="btn-group btn-group-toggle w-100 rounded-pill"
                            data-toggle="buttons">
                            <label
                                class="btn align-middle btn-secondary text-center d-inline-flex align-items-center justify-content-center"
                                :class="search.type_room_id == type.id ? 'active' : ''"
                                v-for="(type, index) in catalogue.type_rooms.data" :key="index"
                                :style="`width:${100 / catalogue.type_rooms.data.length}%`">
                                <input v-if="!catalogue.type_rooms_load" type="radio" :value="type.id"
                                    v-model="search.type_room_id" name="options" id="option_a1" autocomplete="off">
                                <span class="text-repo">{{ type.name }}</span>
                            </label>
                        </div>
                        <div class="row text-center" v-else>
                            <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x" style="margin: 0 auto;"></i>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="form-label text-black-50">Hora de entrada</label>
                        <select class="form-control" name="time_star" v-model="search.time_start">
                            <option value="">Sin hora de entrada</option>
                            <option :value="time" v-for="(time, index_time) in timesStars" :key="index_time">
                                {{ formatHours(time) }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="form-label text-black-50">Hora de salida</label>
                        <select class="form-control" name="time_end" v-model="search.time_end">
                            <option value="">Sin hora de salida</option>
                            <option :value="time" v-for="(time, index_time) in timesEnd" :key="index_time">
                                {{ formatHours(time) }} {{ difHours(time) }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 mt-3" v-if="catalogue.divisions.data">
                        <label class="form-label text-black-50">Selecciona tu división</label>
                        <multiselect @input="loaderTerritories" v-if="!catalogue.divisions_load"
                            selectLabel="Presiona para seleccionar" selectedLabel="Seleccionado"
                            deselectLabel="Presiona para eliminar" v-model="division" label="name" value="id"
                            placeholder="Escribe para buscar" open-direction="bottom" :options="catalogue.divisions.data"
                            :internal-search="true">
                            <span slot="noResult">No se encontraron divisiones con este nombre.</span>
                        </multiselect>
                        <div class="row text-center" v-else>
                            <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x" style="margin: 0 auto;"></i>
                        </div>
                    </div>
                    <div class="col-12 mt-3" v-if="catalogue.territories.data">
                        <label class="form-label text-black-50">Selecciona tu territorio</label>
                        <multiselect @input="loaderRegions" v-if="!catalogue.territories_load"
                            selectLabel="Presiona para seleccionar" selectedLabel="Seleccionado"
                            deselectLabel="Presiona para eliminar" v-model="territory" label="name" value="id"
                            placeholder="Escribe para buscar" open-direction="bottom" :options="catalogue.territories.data"
                            max-height="150"
                            :internal-search="true">
                            <span slot="noResult">No se encontraron territorios con este nombre.</span>
                        </multiselect>
                        <div class="row text-center" v-else>
                            <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x" style="margin: 0 auto;"></i>
                        </div>
                    </div>
                    <div class="col-12 mt-3"
                        v-if="catalogue.regions.data && (search.type_room_id == 1 || search.type_room_id == '')">
                        <label class="form-label text-black-50">Selecciona tu región</label>
                        <multiselect @input="getRegionSelected" v-if="!catalogue.regions_load"
                            selectLabel="Presiona para seleccionar" selectedLabel="Seleccionado"
                            deselectLabel="Presiona para eliminar" v-model="region" label="name" value="id"
                            placeholder="Escribe para buscar" open-direction="bottom" :options="catalogue.regions.data"
                            max-height="75"
                            :internal-search="true">
                            <span slot="noResult">No se encontraron regiones con este nombre.</span>
                            <span slot="noOptions">Sin regiones relacionadas.</span>
                        </multiselect>
                        <div class="row text-center" v-else>
                            <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x" style="margin: 0 auto;"></i>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr>
                        <div class="row">
                            <div class="col-6 d-inline-flex align-items-center">
                                <button class="btn btn-secondary  br20 shaw20" @click="cancelFilter()">
                                    Cerrar
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button class="btn btn-danger  br20 shaw20" @click="clarFilterModal()">
                                    Quitar filtros
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </modal>
            <!-- Fin filtro -->
            <div class="col-12 text-center mt-5 mb-5 pt-3" v-if="load">
                <i class="fa-solid fa-circle-notch mb-3 fa-spin text-muted fa-5x"></i>
                <h3 class="text-muted">Cargando</h3>
            </div>
            <div class="col-12" v-else>
                <div class="row justify-content-center mt-4 pb-4" v-if="lists.data.data.length">
                    <div v-for="(like, index_like) in lists.data.data" :key="index_like" class="col-md-6 col-lg-4 col-12">
                        <div class="card br20 m-2 shaw20">
                            <i v-if="send_like.includes(like.id)" role="button"
                                class="fa-lg fa-solid fa-circle-notch fa-spin btn-like"></i>
                            <i v-if="authUser  && !send_like.includes(like.id)" role="button" class="fa-lg fa-solid fa-heart btn-like"
                                @click="save_favorite(like.id)"
                                :class="exist_favorite(like.id) ? 'text-danger' : 'text-muted'"></i>
                            <img role="button" v-if="like.medias.length > 0" @click="zoomMedia(like.medias)"
                                :src="`${baseFiles}${like.medias[0].media.file_path}${like.medias[0].media.file_name}`"
                                class="card-img-top rounded-top  bg-zoom-img" alt="photo">
                            <img v-else src="@/assets/img/office/1.jpg" class="card-img-top rounded-top" alt="photo">
                            <div class="card-body card-info-room">
                                <div class="div_star">
                                    <i class="fa-solid fa-star"
                                        :class="like.average_score > 0 ? 'text-warning' : 'text-muted'"></i>
                                    <span v-if="like.average_score">{{ parseFloat(like.average_score).toFixed(2) }}</span>
                                </div>
                                <h4 role="button" @click="redirectOffice(like.id)" class="card-title tit">{{ like.name }}
                                </h4>
                                <p class="card-text ml-n2 card_address mt-4 col-10" style="font-size: 12px;">
                                    <a @click="showMap(like)" class="text-muted uppercase">
                                        <i class="fa-solid fa-sm fa-location-pin text-danger mr-1"></i>
                                        {{ like.address }}
                                    </a>
                                </p>
                                <div class="row mt-n3">
                                    <div class="col-2 col-lg-2 text-sm mt-1 mb-1">
                                        <button :aria-label="like.assistants + ' asistentes'"
                                            class="hint--bottom p-0 btn btn-link text-dark">
                                            <i class="fa-solid fa-users-gear fa-lg"></i>
                                        </button>
                                    </div>
                                    <div class="col-2 col-lg-2 text-sm mt-1 mb-1"
                                        v-for="(amenities, amenities_like) in like.amenities" :key="amenities_like"
                                        v-show="amenities_like <= 4">
                                        <button :aria-label="amenities.preference.name + ' asistentes'"
                                            class="hint--bottom p-0 btn btn-link text-dark">
                                            <i :class="amenities.preference.icon" class="fa-lg"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <h5 class="text-success">Disponible</h5>
                                        <p style="font-size: 13px;" class="mt-n1 mb-1">Días disponibles:</p>
                                        <p style="font-size: 13px;" class="mb-2 text-muted">{{ getDays(like.days_available)
                                        }}</p>
                                        <p style="font-size: 13px;" class="mt-n1 mb-1">Horarios:</p>
                                        <p style="font-size: 13px;" class="mt-1 mb-0 text-muted"> {{
                                            like.time_start.substring(0, 5) }} Hrs - {{ like.time_end.substring(0, 5) }} Hrs
                                        </p>
                                        <button @click="redirectOffice(like.id)"
                                            class="btn btn-danger btn-reseve br20 shaw20 pl-4 pr-4">Ver más</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-4">
                        <pagination :pagination="lists.data" :limit="7" v-on:change-page="list" />
                    </div>
                </div>
                <div class="row mt-4 pb-4" v-else>
                    <div class="col-12 text-center p-5">
                        <img src="@/assets/img/not_found.png" class="img-fluid">
                    </div>
                </div>
            </div>
            <ModalMapComponent />
            <ModalMediaZoom />

        </div>
    </div>
</template>
<style scoped>
.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.div_icons {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 130px;
}

@media (min-width: 768px) {
    .b-r-md {
        border-right: 1px solid #a7a7a7;
    }

    .b-b-md {
        border-bottom: 1px solid #a7a7a7;
        margin-bottom: 5px;
    }
}

@media (max-width: 767px) {
    .b-b-xs {
        border-bottom: 1px solid #a7a7a7;
        margin-bottom: 5px;
    }
}

@media (max-width: 546px) {
    .txt-h {
        display: none;
    }

    .icon_res {
        font-size: 20px;
    }


}

.bg-dark {
    border: none !important;
}

button.pt-2 {
    padding-top: 10px !important;
}

button.pb-2 {
    padding-bottom: 10px !important;
}
</style>