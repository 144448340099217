<script>
import { mapActions } from 'vuex';
import request from '../services/request'
import VueNumericInput from 'vue-numeric-input'
export default {
    components: {
        VueNumericInput
    },
    data() {
        return {
            form: {
                room_id: '',
                stock: [],
            },
            items: [],
            room_id: '',
            stockForm: [],
            room: {},
            load: false,
        }
    },
    methods: {
        closedModal() {
            this.form.room_id = '';
            this.form.stock = [];
            this.stockForm = [];
            this.$modal.hide('ModalStockCharge');
        },
        existValuePosition(id) {
            let stock = this.items;
            const position = stock.findIndex((element) => element.id === id);
            return position;
        },
        async sendForm() {
            let service = request;
            this.form.room_id = this.room.id;
            this.form.stock = this.stockForm;
            let form = this.form;
            let _endpoint = `/booking/inventoryCharge`;
            try {
                let { data } = await service.post(_endpoint, form)
                if (data) {
                    await this.successNotification('Se guardó la información correctamente.');
                    this.closedModal();
                    console.log(data);
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async getStock() {
            let vm = this;
            let service = request;
            let room_id = this.room_id;
            let _endpoint = `/booking/inventory/${room_id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data.data);
                    vm.items = data.data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async dataFormat() {
            let vm = this;
            this.items.forEach(function (item) {
                let _stock = {
                    item_id: item.id,
                    quantity : 0
                };
                vm.stockForm.push(_stock);
            });
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-inventory", async (room) => {
            this.load = true;
            this.$modal.show('ModalStockCharge');
            if (room) {
                this.room = room;
                this.room_id = room.id;
                await this.getStock();
                await this.dataFormat();
                this.load = false;

            }
        });
    },
}
</script>
<template>
    <modal name="ModalStockCharge" v-bind:click-to-close="false" :width="'95%'" :maxWidth="700" :adaptive="true" :shiftY="0.1">
        <div style="background-color: #343a40;">
            <h5 class="p-3 text-white">
                Inventario de {{ room.name }}
                <br v-if="room"><span v-if="room" style="font-size: 14px;">
                </span>
                <button class="btn btn-sm btn-danger btn-closed-modal mt-2 float-right br20" @click="closedModal">
                    <i class="fa-solid fa-x"></i>
                </button>
            </h5>
        </div>
        <div class="row" v-if="load">
            <div class="col-12 text-center p-5">
                <i class="fa-solid fa-circle-notch mb-3 fa-spin text-muted fa-5x"></i>
                <h3 class="text-muted">Cargando</h3>
            </div>
        </div>
        <div v-else class="row p-3">
            <div class="col-12 table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Item</th>
                            <th class="text-center">Entradas</th>
                            <th class="text-center">Salidas</th>
                            <th class="text-center">Existencias</th>
                            <th class="text-center">Unidades a cargar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index_item) in items" :key="index_item">
                            <td class="text-center">{{ index_item + 1 }}</td>
                            <td>{{ item.description }}</td>
                            <td class="text-center">{{ item.sum_entrances }}</td>
                            <td class="text-center">{{ item.sum_discharges ? item.sum_discharges : 0 }}</td>
                            <td class="text-center">{{ item.stock }}</td>
                            <td class="text-center">
                                <vue-numeric-input  v-model="stockForm[existValuePosition(item.id)].quantity" :min="0" :max="parseInt(item.stock)" align="center" ></vue-numeric-input>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="col-12">
                <hr>
                <button class="btn btn-secondary btn-sm" @click="closedModal()">
                    <i class="fa-solid fa-rotate-left"></i> Regresar
                </button>
                <button type="button" @click="sendForm()" class="btn btn-danger btn-sm float-right">
                    <i class="fas fa-save"></i> Guardar
                </button>
            </div>
        </div>
    </modal>
</template>
<style>
.btn-closed-modal {
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>