<script>
import ProfileUser from '@/components/ProfileUserComponent.vue';
import PasswordUser from '@/components/PasswordUserComponent.vue';
import FrequentQuestions from '@/components/FrequentQuestionsComponent.vue';
import { mapMutations } from 'vuex';

export default {
    components: {
        ProfileUser,
        PasswordUser,
        FrequentQuestions
    },
    methods: {
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        })
    },
    mounted() {
        this.setHeaderTitle(`Perfil`);
    }
}
</script>
<template>
    <div class="container">

        <div class="row">
            <div class="col-12 slider">
                <div class="row mt-5">
                    <div class="col-12 col-sm-4">
                        <div class="nav flex-column nav-tabs 3" id="vert-tabs-tab" role="tablist"
                            aria-orientation="vertical">
                            <a class="nav-link div-link text-secondary rounded-top active pt-4 pb-4" id="vert-tabs-user-tab"
                                data-toggle="pill" href="#vert-tabs-user" role="tab" aria-controls="vert-tabs-user"
                                aria-selected="true">
                                <div class="div-icon rounded-circle">
                                    <i class="fa-solid fa-user"></i>
                                </div>
                                Datos personales
                            </a>
                            <a class="nav-link div-link text-secondary  pt-4 pb-4" id="vert-tabs-pass-tab"
                                data-toggle="pill" href="#vert-tabs-pass" role="tab" aria-controls="vert-tabs-pass"
                                aria-selected="false">
                                <div class="div-icon rounded-circle">
                                    <i class="fa-solid fa-lock"></i>
                                </div>
                                Seguridad
                            </a>
                            <a class="nav-link div-link text-secondary rounded-bottom  pt-4 pb-4"
                                id="vert-tabs-settings-tab" data-toggle="pill" href="#vert-tabs-settings" role="tab"
                                aria-controls="vert-tabs-settings" aria-selected="false">
                                <div class="div-icon rounded-circle">
                                    <i class="fa-solid fa-question"></i>
                                </div>
                                Preguntas frecuentes
                            </a>
                        </div>
                    </div>
                    <div class="col-12 mt-m-4 col-sm-8">
                        <div class="tab-content" id="vert-tabs-tabContent">
                            <div class="tab-pane text-left fade show active" id="vert-tabs-user" role="tabpanel"
                                aria-labelledby="vert-tabs-user-tab">
                                <ProfileUser />
                            </div>
                            <div class="tab-pane fade" id="vert-tabs-pass" role="tabpanel"
                                aria-labelledby="vert-tabs-pass-tab">
                                <PasswordUser />
                            </div>
                            <div class="tab-pane fade" id="vert-tabs-settings" role="tabpanel"
                                aria-labelledby="vert-tabs-settings-tab">
                                <FrequentQuestions />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.div-icon {
    display: initial;
    background: #575a5b;
    color: white;
    padding: 10px 13px;
    width: 40px;
    height: 40px;
}

.div-link {
    border: 1px solid #babcbc !important;
}

.nav-tabs.flex-column {
    border-right: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #ff0400 !important;
    color: white !important;
}

@media (max-width: 575px) {
    .mt-m-4 {
        margin-top: 20px;
    }

    .div-icon {
        margin: auto;
    }

    div#vert-tabs-tab a {
        width: 33.33%;
        display: grid;
        text-align: center;
    }

    div#vert-tabs-tab {
        display: -webkit-box;
    }
}</style>