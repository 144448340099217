import axios from "axios"
// import  from "domain"
import base_endpoint from './domain'

const ENDPOINT = base_endpoint + '/auth';

export const login = (payload) => {
    let endpoint = `${ENDPOINT}/login`
    return axios.post(
        endpoint,
        payload
    )
}

export const register = () => {
    let endpoint = `${ENDPOINT}/register`
    return axios.post(
        endpoint,
    )
}

export const logout = () => {
    let endpoint = `${ENDPOINT}/logout`
    return axios.post(endpoint)
}

export const getUser = () => {
    let endpoint = `${ENDPOINT}/me`
    return axios.get(endpoint);
}

export const updateUser = () => {
    let endpoint = `${ENDPOINT}/user`
    return axios.post(endpoint, )
}


export default {
    login,
}
