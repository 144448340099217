<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
	},
	data() {
		return {
			imageBackground: `/img/oficina.jpg`,
			widthScreen: window.screen.width,
			showMenu: false,
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user',
			headerTitle: 'getHeaderTitle'
		})
	},
	methods: {
		async postLogout() {
			try {
				let logout = await this.authLogout()
				if (logout) {
					this.$nextTick(() => {
						location.reload()
					})
				}
			} catch (error) {
				console.log(error);
			}
		},
		showAdmin() {
			let valid = false;
			if (this.authUser) {
				if (this.authUser.admin) {
					valid = true;
				}
			}
			return valid;
		},
		toogleMunu() {
			this.showMenu = this.showMenu ? false : true;
		},
		handleResize() {
			this.widthScreen = window.innerWidth;
		},
		redirectName(name) {
			this.showMenu = false;
			this.$router.push({ name: name })
		},
		wBody() {
			if (this.showMenu && this.widthScreen <= 767) {
				return "width: 100%; position: fixed;";
			} else {
				return '';
			}
		},
		...mapActions({
			authLogout: 'auth/logout'
		})
	},
	created() {
		window.addEventListener("resize", this.handleResize);
	},
	destroyed() {
		window.removeEventListener("resize", this.handleResize);
	},
	mounted() {
		this.showMenu = this.$store.state.status.showMenu;
	}
}
</script>
<template>
	<div class="wrapper" :style="wBody()">
		<ul class="border-head">
			<li></li>
			<li></li>
		</ul>
		<nav class="main-header navbar navbar-expand-md navbar-light navbar-dark d-block d-sm-block d-md-none d-lg-none d-xl-none"
			style=" background: #dc3545 !important">
			<router-link :to="{ name: 'home' }">
				<img src="@/assets/img/logo_elektra_blanco.png" alt="Elektra Logo" style="width: 110px;">
			</router-link>
			<button style="border-radius: 30px;" v-if="authUser" @click="toogleMunu()"
				class="float-right btn btn-sm btn-outline-light mr-2 mt-2">
				<i v-if="showMenu" class="text-white fa-solid fa-times"></i>
				<i v-else class="text-white fa-solid fa-bars"></i>
			</button>
			<router-link v-else :to="{ name: 'login' }" style="border-radius: 30px;"
				class="float-right btn btn-sm btn-outline-light mr-2 mt-2">
				<i class="text-white fa-solid fa-circle-user"></i>
			</router-link>
			<Transition name="bounce">
				<div class="menu-mobil d-flex" v-if="showMenu">
					<div class="row justify-content-center">
						<div class="col-12 d-flex justify-content-center mt-3 mb-2" v-if="authUser">
							<i class="fa-regular text-secondary fa-circle-user fa-3x mr-2 ml-2"></i>
							<div class="ml-2 mt-n1" style="line-height: 1.2;">
								<strong style="font-size: 14px;">Bienvenid@</strong><br />
								<strong v-if="authUser.full_name" class="pt-n4 text-justify" style="font-size: 15px;">{{
									authUser.full_name }}</strong>
								<strong v-else class="pt-n4 text-justify" style="font-size: 15px;">{{ authUser.first_name }}
									{{ authUser.last_name }}</strong>
							</div>
						</div>
						<div class="col-6 text-center">
							<button class="text-muted btn btn-light btn-block" @click="redirectName('home')">
								<i class="fa-3x text-secondary fa fa-search" aria-hidden="true"></i><br /><br />
								<span style="font-size: 19px;">Buscar</span>
							</button>
						</div>
						<div class="col-6 text-center">
							<button class="text-muted btn btn-light btn-block" @click="redirectName('favorites')">
								<i class="fa-3x text-secondary fa-solid fa-heart"></i><br /><br /> <span
									style="font-size: 19px;">Favoritos</span>
							</button>
						</div>
						<div class="col-6 text-center">
							<button class="text-muted btn btn-light btn-block" @click="redirectName('bookings')">
								<i class="fa-3x text-secondary fa-regular fa-calendar-check"></i><br /><br /> <span
									style="font-size: 19px;">Reservas</span>
							</button>
						</div>
						<div class="col-6 text-center">
							<button class="text-muted btn btn-light btn-block" @click="redirectName('profile')">
								<i class="fa-3x text-secondary fa-regular fa-circle-user"></i><br /><br /> <span
									style="font-size: 19px;">Perfil</span>
							</button>
						</div>
						<div class="col-6 text-center" v-if="showAdmin()">
							<button class="text-muted btn btn-light btn-block" @click="redirectName('myRoom')">
								<i class="fa-3x text-secondary fa-solid fa-building-circle-arrow-right"></i><br /><br />
								<span style="font-size: 19px;">Administración de Reservas</span>
							</button>
						</div>
						<!-- <div class="col-6 text-center" v-if="showAdmin()">
							<button class="text-muted btn btn-light btn-block" @click="redirectName('myRooms')">
								<i class="fa-3x text-secondary fa-solid fa-boxes-stacked"></i><br /><br />
								<span style="font-size: 19px;">Administración de inventario</span>
							</button>
						</div> -->
						<div class="col-12 text-center mt-4 mb-4">
							<button type="buttom" @click="postLogout" class="btn btn-lg btn-danger br20 shaw20 pl-4 pr-4">
								<span class=" mr-3">Cerrar sesión</span>
								<i class="float-right mt-1 fa-solid fa-right-from-bracket"></i>
							</button>
						</div>
					</div>
				</div>
			</Transition>
		</nav>
		<nav class="main-header navbar navbar-expand-md navbar-light navbar-white d-none d-sm-none d-md-block d-lg-block d-xl-block"
			style=" background: #dc3545 !important;">
			<div class="container ">
				<router-link :to="{ name: 'home' }" class="navbar-brand">
					<img src="@/assets/img/logo_elektra_blanco.png" alt="Elektra Logo" class="brand-image"
						style="opacity: .8; height: 45px;">
					<span class=" ml-3 brand-secondary font-weight-light text-light"
						style="font-size: 15px; font-weight: 600 !important;">Módulo Sala de Reservas</span>
				</router-link>
				<button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse"
					aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
					<li class="nav-item dropdown">
						<router-link v-if="!authUser" class="text-light d-flex" :to="{ name: 'login' }">
							<i class="fa-regular fa-circle-user fa-2x"></i>
							<div class="ml-2 mt-n1" style="line-height: 1.2;">
								<strong style="font-size: 10px;">Hola</strong><br />
								<strong class="pt-n4" style="font-size: 13px;">Iniciar sesión</strong>
							</div>
						</router-link>
						<a v-if="authUser" class="nav-link d-flex text-light mt-n3" data-toggle="dropdown" href="#">
							<i class="fa-regular fa-circle-user fa-2x"></i>
							<div class="ml-2 mt-n1" style="line-height: 1.2;">
								<strong style="font-size: 10px;">Bienvenid@</strong><br />
								<strong v-if="authUser.full_name" class="pt-n4" style="font-size: 13px;">{{
									authUser.full_name }}</strong>
								<strong v-else class="pt-n4" style="font-size: 13px;">{{ authUser.first_name }} {{
									authUser.last_name }}</strong>
							</div>
							<!-- <div class="ml-2 mt-1">
								<strong>Iniciar sesion</strong>
							</div> -->
						</a>
						<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right br20">
							<router-link :to="{ name: 'profile' }" class="dropdown-item p-2">
								<span class="mr-3">Perfil</span>
								<i class="float-right text-danger ml-3 fa-solid fa-user-gear"></i>
							</router-link>
							<div class="dropdown-divider"></div>
							<router-link :to="{ name: 'favorites' }" class="dropdown-item p-2">
								<span class=" mr-3">Favoritos</span>
								<i class="float-right text-danger ml-3 fa-solid fa-heart"></i>
							</router-link>
							<div class="dropdown-divider"></div>
							<router-link :to="{ name: 'bookings' }" class="dropdown-item p-2">
								<span class=" mr-3">Reservas</span>
								<i class="float-right text-danger ml-3 fa-regular fa-calendar-check"></i>
							</router-link>
							<div class="dropdown-divider" v-if="showAdmin()"></div>
							<router-link :to="{ name: 'myRoom' }" v-if="showAdmin()" class="dropdown-item p-2">
								<span class=" mr-3">Administración de Reservas</span>
								<i class="float-right text-danger ml-3 fa-solid fa-building-circle-arrow-right"></i>
							</router-link>
							<div class="dropdown-divider" v-if="showAdmin()"></div>
							<!-- <router-link :to="{ name: 'myRooms' }" v-if="showAdmin()" class="dropdown-item p-2">
								<span class=" mr-3">Administración de inventario</span>
								<i class="float-right text-danger fa-solid fa-boxes-stacked ml-3 fa-solid fa-building-circle-arrow-right"></i>
							</router-link> -->
							<div class="dropdown-divider"></div>
							<a @click="postLogout" class="dropdown-item p-2">
								<span class=" mr-3">Cerrar sesión</span>
								<i class="float-right text-danger ml-3 fa-solid fa-right-from-bracket"></i>
							</a>
						</div>
					</li>
				</ul>
			</div>
		</nav>

		<!-- SUBMENU -->
		<!-- <nav class="navbar sub-menu navbar-expand navbar-white navbar-light d-none d-sm-none d-md-block d-lg-block d-xl-block" v-if="$route.name == 'home'"
			style="z-index: 3;">
			<div class="container">
				<ul class="navbar-nav">
					<li class="nav-item content-nav">
						<a href="#"  class="nav-link text-r-n">Reservar</a>
					</li>
					<li class="nav-item content-nav">
						<a href="#"  class="nav-link text-r-n">Reservaciones activas</a>
					</li>
					<li class="nav-item">
						<a href="#" class="nav-link text-r-n">Calendario</a>
					</li>
				</ul>

			</div>
		</nav> -->
		<!-- SUBMENU -->

		<!-- Title -->
		<div style="background-color: #dfe0df;" v-if="this.$route.name != 'home'">
			<div class="container ">
				<h2 class="mb-0" style="padding: 30px 0px; color: #5c5c5c!important;">
					{{ headerTitle }}
				</h2>
			</div>
		</div>
		<!-- Title -->


		<div class="content-wrapper">
			<!-- Main content -->
			<section class="content p-0">
				<router-view></router-view>

			</section>
		</div>
	</div>
</template>
<style>
.btn-menu {
	position: absolute;
	left: 20px;
	z-index: 1;
	top: 5px;
}

.content-wrapper {
	padding-bottom: 40px;
}

/* .wrapper {
	background-image: url('@/assets/img/fondo.png');
	height: 100vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 900px !important;
}

.content-wrapper {
	background-image: url('@/assets/img/fondo_01.jpg');
	height: 100vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
} */
.bounce-enter-active {
	animation: bounce-in 0.5s;
}

.bounce-leave-active {
	animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(1);
	}
}

.menu-mobil {
	margin-left: -8px;
	position: fixed;
	bottom: 0px;
	padding: 10px;
	background: #f9f9f7;
	width: 100%;
	height: calc(100% - 65px);
}

.border-head {
	z-index: 2;
	position: relative;
	list-style: none;
	width: 100%;
	font-size: 0;
	padding: 0px;
	margin: 0px;
	background: #dc3545;
}

.border-head li {
	display: inline-block;
	width: 30%;
	height: 3px;
}

.border-head li:nth-child(1) {
	width: 20%;
	background: #474e57;
}

.border-head li:nth-child(2) {
	background: #fff;
}

.b-r {
	border-right: 2px solid #cdcfd1;
}

.sub-menu {
	font-weight: 700;
	color: rgba(0, 0, 0, .5) !important;
}

@media (max-width: 575px) {
	ul.navbar-nav {
		width: 100%;
	}

	.content-nav {
		width: 33.33%;
	}

	.text-r-n {
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	/* .sub-menu {
		font-size: 13px;
		font-weight: 700;
		text-align: center;
	}

	.sub-menu .navbar-nav {
		margin: 0 auto;
	} */
}


.vdp-datepicker__calendar {
	background: white !important;
	border: 0px !important;
}

.vdp-datepicker__calendar .cell.selected {
	background: #dc3545 !important;
	border-radius: 40px !important;
	color: #fff !important;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
	border: 1px solid #dc3545 !important;
	border-radius: 40px !important;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	font-family: 'fontAwesome';
}

.owl-prev,
.owl-next {
	font-size: 0px !important;
}

.owl-prev {
	position: absolute;
	z-index: 11;
	background: #dc3545 !important;
	top: 45%;
	left: 0px;
	padding: 8px;
	font-size: 10px;
}

.owl-next {
	position: absolute;
	z-index: 11;
	background: #dc3545 !important;
	top: 45%;
	right: 0px;
	padding: 8px;
	font-size: 10px;
}

.owl-carousel .owl-nav .owl-prev:before {
	content: "\f053";
	font-size: 20px;

}

.owl-carousel .owl-nav .owl-next:after {
	content: "\f054";
	font-size: 20px;
}

.dropdown-item:focus,
.dropdown-item:hover {
	background-color: #474e57 !important;
	color: #fff !important;
}

.dropdown-item:hover:first-child,
.dropdown-item:hover:active:first-child,
.dropdown-item:hover:focus:first-child {
	border-radius: 17px 17px 0px 0px;
}

.dropdown-item:hover:last-child,
.dropdown-item:hover:active:last-child,
.dropdown-item:hover:focus:last-child {
	border-radius: 0px 0px 17px 17px;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff !important;
	background-color: #474e57 !important;
}</style>
