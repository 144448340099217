<script>
import { mapGetters, mapActions } from 'vuex';
import request from '../services/request'

export default {
    data() {
        return {           
            showP: false,
            showPC: false,
            password: '',
            passwordConfirm: '',
            errors: {},
            submitted: false
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user'
        })
    },
    methods: {
        async updateUpdate() {
            if (this.password != this.passwordConfirm) {
                this.$swal({
                    title: 'La contraseña debe ser igual',
                    text: 'Las contraseñas no son identicas',
                    icon: 'warning',
                })
                return false;
            }
            this.submitted = true;
            try {                
                let _endpoint = '/update_password_connected';
                let service = request;
                let reserve = {password: this.password };

                let { data } = await service.post(_endpoint, reserve)
                if (data) {
                    this.password = '';
                    this.passwordConfirm = '';
                    this.submitted = false;
                    await this.successNotification('Éxito al modificar la contraseña.');
                }
            } catch (error) {
                this.submitted = false;
                this.errors = await this.errorNotification(error)
            }
        },
        Ctype(type) {
            if (type == 'showP') {
                this.showP = this.showP ? false : true;
            }
            if (type == 'showPC') {
                this.showPC = this.showPC ? false : true;
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    }
}
</script>
<template>
    <form @submit.prevent="updateUpdate">
        <div class="row">
            <div class="col-12">
                <h2>Seguridad</h2>
                <h4 class="text-muted">Actualizar tu contraseña</h4>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Contraseña:
                    </div>
                    <div class="col-8">
                        <div class="input-group">
                            <input v-model="password" :type="showP ? 'text' : 'password'" class="form-control"
                                required>
                            <div class="input-group-prepend" @click="Ctype('showP')">
                                <div class="input-group-text btn bg-danger">
                                    <i v-if="showP"   class="fa-solid fa-eye-slash"></i>
                                    <i v-else class="fa-solid fa-eye"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Confirmar Contraseña:
                    </div>
                    <div class="col-8">
                        <div class="input-group">
                            <input v-model="passwordConfirm" :type="showPC ? 'text' : 'password'" class="form-control"
                                required>
                            <div class="input-group-prepend" @click="Ctype('showPC')">
                                <div class="input-group-text btn bg-danger">
                                    <i v-if="showPC"   class="fa-solid fa-eye-slash"></i>
                                    <i v-else class="fa-solid fa-eye"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12 text-right">
                <button class="btn btn-danger br20 shaw20 pl-4 pr-4" :disabled="submitted">
                    <span v-if="submitted">
                        <i class="fa-solid fa-spinner fa-spin-pulse mr-1"></i> Guardando
                    </span>
                    <span v-else>
                        <i class="fa-solid fa-floppy-disk"></i> Guardar
                    </span> 
                </button>
            </div>
        </div>
    </form>
</template>