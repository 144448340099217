<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import request from '../services/request'
import pagination from '@/components/CustomPagination.vue'
import InventoryCharge from '@/components/InventoryChargeModalComponent.vue';
import InventoryDischarge from '@/components/InventoryDischargeModalComponent.vue';

export default {
    components: {
        InventoryCharge,
        InventoryDischarge,
        pagination
    },
    data() {
        return {
            for_page: 25,
            status: 1,
            room_id: '',
            send: false,
            sending: false,
            rooms: {
                data: []
            },
        }
    },
    methods: {
        async getRooms(page = 1) {
            this.rooms.data = [];
            this.sending = true;
            let service = request;
            let for_page = this.for_page;
            let _endpoint = `/booking/list_room_admin?page=${page}&n=${for_page}`;
            try {
                let { data } = await service.get(_endpoint);
                if (data) {
                    this.rooms = data.data;
                    this.sending = false;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        showInvetory(room = {}){
            Event.$emit('event-show-inventory', room);
        },
        showInvetoryDis(room = {}){
            Event.$emit('event-show-inventory-dis', room);
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },
    async mounted() {
        this.setHeaderTitle(`Inventario de Mis Salas`);
        if (this.authUser.admin) {
            await this.getRooms();
        } else {
            this.$router.push({ name: 'home' })
        }
    },
}
</script>
<template>
    <div class="row m-0 ">
        <div class="container pt-4">
            <div class="col-12 mt-5">
                <div class="card br20 shaw20">
                    <div class="card-body">
                        <div class="row bg-dark br20 text-white p-2">
                            <div class="col-12 col-md-12">
                                <select v-model="for_page" class="form-control bg-dark">
                                    <option value="25">Mostrar 25</option>
                                    <option value="50">Mostrar 50</option>
                                    <option value="100">Mostrar 100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="card br20 shaw20">
                    <div class="card-body">
                        <div class="row">                           
                            <div class="col-12 table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th class="text-center">No.</th>
                                            <th class="text-center">CECO</th>
                                            <th class="text-center">Sala</th>
                                            <th class="text-center">Opciones de Item</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="sending">
                                            <td colspan="6" class="text-center p-4">
                                                <i class="fa-solid fa-circle-notch fa-spin text-muted fa-3x"></i>
                                                <h5 class="text-muted">Cargando</h5>
                                            </td>
                                        </tr>
                                        <tr v-show="rooms.data.length > 0" v-for="(room, index_room) in rooms.data"
                                            :key="index_room">
                                            <td class="text-center">
                                                {{ index_room + 1}}
                                            </td>
                                            <td class="text-center">
                                                {{ room.ceco }}
                                            </td>
                                            <td class="text-center">
                                                {{ room.name }}
                                            </td>
                                            <td class="text-center">
                                                <button type="button" @click="showInvetory(room)"
                                                    class="btn m-1 btn-sm btn-info">
                                                    <i class="fa-solid fa-boxes-packing"></i> Cargar
                                                </button>
                                                <button type="button" @click="showInvetoryDis(room)"
                                                    class="btn m-1 btn-sm btn-warning">
                                                    <i class="fa-solid fa-square-minus"></i> Descargar
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="rooms.data.length == 0 && !sending">
                                            <td colspan="6" class="text-center p-4">
                                                Sin resultados.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                                <pagination :pagination="rooms" :limit="7" v-on:change-page="getRooms" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <inventory-charge />
        <inventory-discharge />
    </div>
</template>
<style scoped>
@media (min-width: 768px) {
    .b-r-md {
        border-right: 1px solid #fff;
    }
}

.bg-dark {
    border: none !important;
}
</style>
