<script>
import carousel from 'vue-owl-carousel'

export default {
    components: {
        carousel,
    },
    data() {
        return {
            media: {},
            baseFiles: this.$BaseUrlFiles,
        }
    },
    methods: {
        closed() {
            this.$modal.hide('modalZoomMedia');
        }
    },
    created() {
        Event.$on("event-zoom-media", (media) => {
            this.media = media;
            console.log(media);
            console.log('aijcdoijcaoicjoda');
            this.$modal.show('modalZoomMedia');
        });
    },
}
</script>
<template>
    <modal name="modalZoomMedia" :width="'95%'" :height="'auto'" :maxWidth="1200" :adaptive="true" :shiftY="0.1" styles="background-color: transparent; box-shadow: none; overflow: initial;">
        <div style="background-color: #343a40;">
            <h5 class="p-0 text-white uppercase">
                <button class="btn btn-sm btn-danger btn-closed-modal float-right br20" style="z-index: 10;"
                    @click="closed">
                    <i class="fa-solid fa-x"></i>
                </button>
            </h5>
        </div>
        <div class="p-0">
            <carousel :autoplay="false" v-if="media" :items="1" :nav="true" :margin="1" :loop="true" :autoHeight="true">
                <img v-for="(media, index_icon) in media" :key="index_icon" class="img-fluid rounded-r"
                    :src="`${baseFiles}${media.media.file_path}${media.media.file_name}`" alt="photo">
            </carousel>
        </div>
    </modal>
</template>
<style scoped>
.btn-closed-modal {
    position: absolute;
    right: 10px;
    top: 10px;
}

img {
    width: 100%;
    min-height: 100%;
    max-height: 900px!important;
    object-fit: cover;
    object-position: center;
}
</style>