<script>
import SerachBookings from '@/components/SerachBookingsComponent.vue';
import CarouselAmenities from '@/components/AmenitiesComponent.vue';
import RandomRooms from '@/components/RandomRoomsComponent.vue';
import VideoBg from 'vue-videobg'

export default {
    components: {
        SerachBookings,
        CarouselAmenities,
        RandomRooms,
        VideoBg
    },
    methods: {
        async checkQueryToken() {
            let vm = this;
            if (vm.$route.query && Object.keys(vm.$route.query).length > 0) {
                vm.$router.push({ name: "login", query: vm.$route.query });
            }
        }
    },
    async mounted() {
        await this.checkQueryToken();
    }
}
</script>
<template>
    <div class="p-0">
        <!-- <div class="bk_home">
        </div> -->
        <div class="bk_home">
            <video-bg :sources="[require('@/assets/img/reserva.mp4')]" :img="require('@/assets/img/front.png')">
                <div class="row h-100 ml-1 mr-1 align-items-center justify-content-center">
                    <div class="container">
                        <div class="col-12">
                            <SerachBookings />
                        </div>
                    </div>
                </div>
            </video-bg>
        </div>
        <div class="p-grees">
            <div class="container mt-3 mb-3">
                <CarouselAmenities />
            </div>
        </div>
        <div class="container pt-4">
            <div class="row">
                <div class="col-12">
                    <h2>Salas más reservadas</h2>
                    <hr>
                </div>
            </div>
            <RandomRooms />
        </div>


    </div>
</template>

<style>
.p-grees {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background-color: #f3f3f3;
    width: 100%;
}

.bk_home {
    /* padding: 200px 0px; */
    /* background-image: url(@/assets/img/back_home.jpg) !important; */
    /* background-image: url(@/assets/img/fondo_01.jpg) !important; */
    background-position: center;
    background-size: transform;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
    left: 0px;
    top: 0px;
}

.rounded-c {
    border-radius: 30px !important;
}


.mt-search-3 {
    margin-top: 10px;
}

@media (max-width: 575px) {
    .mt-search-3 {
        margin-top: -30px;
    }
}
</style>