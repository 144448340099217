import { render, staticRenderFns } from "./BookingUpdateModalComponent.vue?vue&type=template&id=218a5f62"
import script from "./BookingUpdateModalComponent.vue?vue&type=script&lang=js"
export * from "./BookingUpdateModalComponent.vue?vue&type=script&lang=js"
import style0 from "./BookingUpdateModalComponent.vue?vue&type=style&index=0&id=218a5f62&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports