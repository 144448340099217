import axios from "axios";
import base_endpoint from './domain'

const URL = base_endpoint;

export default {
    list(endpoint=null, page=1, search=null) {
        let showPages = 50;
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0]=='/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.get(final_endpoint, {params: { page:page, search, n: showPages}})
        } else {
            return;
        }
    },
    get(endpoint=null, params={}) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0]=='/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.get(final_endpoint, {params})
        } else {
            return;
        }
    },
    post(endpoint=null, payload) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0]=='/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.post(final_endpoint, payload);
        } else {
            return;
        }
    },
	postMedia(endpoint=null, payload) {
			if (endpoint) {
					let final_endpoint;
					if (endpoint[0]=='/') {
							final_endpoint = `${URL}${endpoint}`;
					} else {
							final_endpoint = `${URL}/${endpoint}`;
					}

					return axios.post(final_endpoint, payload,  {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					});
			} else {
					return;
			}
	},
    destroy(endpoint) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0]=='/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.delete(final_endpoint);
        } else {
            return;
        }
    },
    logout() {
        return axios.post('/logout');
    },
}
