<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import request from '../services/request'
import Multiselect from 'vue-multiselect'
import Catalogue from '@/services/catalogue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
// import html2canvas from 'html2canvas';
// import loginModal from '@/components/ModalLoginComponent.vue'

import moment from 'moment';

export default {
    components: {
        DatePicker,
        // loginModal
        Multiselect,
    },
    props: {
        current_model: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            office: {},
            reserve: {
                id: '',
                date: '',
                date_departure: '',
                room_id: '',
                participants: '',
                time_start: '',
                time_end: '',
                udn: '',
                course: '',
                udn_id: '',
                course_id: '',
                topics: '',
                cofeeebreak: '',
                course_other: '',
            },
            timesUseds: '',
            timesStars: '',
            timesEnd: '',
            udn: '',
            course: '',
            baseFiles: this.$BaseUrlFiles,
            send: false,
            momentFormat: {
                stringify: (date) => {
                    return date ? this.moment(date).format('dddd') + ' ' + this.moment(date).format('LL') : ''
                },
                parse: (value) => {
                    return value ? this.moment(value, 'DD-MM-YYYY').toDate() : ''
                }
            }
        }
    },
    methods: {
        disabledRange: function (date) {
            let days = this.getDays();
            let nDay = new Date(date).getDay();
            let offDays = this.office.days_not_avialable;
            let fortDate = moment(date).format('YYYY-MM-DD');
            return date <= moment().subtract(1, 'd') || days.includes(nDay) || offDays.includes(fortDate);
        },
        async get_office() {
            let service = request;
            let _endpoint = `/booking/rooms/${this.reserve.room_id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.office = data.data;
                    //this.catchParameters();

                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        loopAssistants() {
            let assistants = this.office.assistants;
            let numbers = [];
            for (let index = 1; index <= assistants; index++) {
                numbers.push(index);
            }
            return numbers;
        },
        getDays() {
            let arrayDays = JSON.parse(this.office.days_available);
            let days = [];
            // 0 - Domingo
            // 6 - Sabado
            let daysWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            daysWeek.forEach(function (day) {
                if (!arrayDays.includes(day)) {
                    if (day == 'sunday') {
                        days.push(0);
                    }
                    if (day == 'monday') {
                        days.push(1);
                    }
                    if (day == 'tuesday') {
                        days.push(2);
                    }
                    if (day == 'wednesday') {
                        days.push(3);
                    }
                    if (day == 'thursday') {
                        days.push(4);
                    }
                    if (day == 'friday') {
                        days.push(5);
                    }
                    if (day == 'saturday') {
                        days.push(6);
                    }
                }
            });
            return days;
        },
        async showTime(data) {
            console.log(data);

            let dInit = moment(this.reserve.date[0]).format('YYYY-MM-DD');
            let dEnd = moment(this.reserve.date[1]).format('YYYY-MM-DD');

            let service = request;
            this.catNEvent();
            let _endpoint = `/catalogue/times_available?room_id=${this.reserve.room_id}&date_start=${dInit}&date_end=${dEnd}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data.data.reserved_times);
                    this.timesUseds = [];
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
            await this.getTimes(this.office.time_start, this.office.time_end);
        },
        getTimes(start = '09:00:00', end = '21:00:00', returnA = false) {
            let timesInBetween = [];
            let startH = parseInt(start.split(":")[0]);
            let startM = parseInt(start.split(":")[1]);
            let endH = parseInt(end.split(":")[0]);
            let endM = parseInt(end.split(":")[1]);

            if (startM == 30)
                startH++;
            for (let i = startH; i < endH; i++) {
                timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
                timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
            }
            timesInBetween.push(endH < 10 ? "0" + endH + ":00" : endH + ":00");
            if (endM == 30)
                timesInBetween.push(endH < 10 ? "0" + endH + ":30" : endH + ":30");

            if (returnA) {
                return timesInBetween;
            } else {
                timesInBetween.pop();
                this.timesStars = timesInBetween;
            }
        },
        statusHorario(time) {
            // console.log(time);
            let resul = false;
            let range = this.timesUseds;
            if (range.includes(time)) {
                resul = true;
            }
            // if (timesUseds) {
            //     timesUseds.forEach(timesU => {
            //         let range = this.getTimes(timesU.time_start, timesU.time_end, true);
            //         console.log(range);
            //         if (range) {
            //             if (range.includes(time)) {
            //                 resul = true;
            //             }
            //         }
            //     });
            // }
            return resul;
        },
        formatHours(time) {
            return moment(time, "HHmm").format("hh:mm a");
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        difHours(time) {
            if (this.reserve.time_start) {
                var hora1 = time.split(":"),
                    hora2 = this.reserve.time_start.split(":"),
                    t1 = new Date(),
                    t2 = new Date();

                t1.setHours(hora1[0], hora1[1]);
                t2.setHours(hora2[0], hora2[1]);

                //Aquí hago la resta
                t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes(), t1.getSeconds() - t2.getSeconds());

                let h = (t1.getHours() ? t1.getHours() + (t1.getHours() > 1 ? " hrs" : " hr ") : "");
                let m = (t1.getMinutes() ? t1.getMinutes() + (t1.getMinutes() > 1 ? " min" : " min") : "");

                let text = '';
                if (h != "") {
                    text += h;
                    if (m != "") {
                        text += ' y ';
                    }
                }

                if (m != "") {
                    text += m;
                }

                if (text == '') {
                    text = '0 min'
                }

                return ' - (' + text + ')';
            } else {
                return '';
            }
        },
        catchRange(time) {
            let hours = this.getTimes(this.office.time_start, this.office.time_end, true);
            let prv = [];
            let brk = false;
            if (hours) {
                hours.forEach(timesU => {
                    if (time < timesU) {
                        if (this.statusHorario(timesU)) {
                            if (!brk) {
                                prv.push(timesU);
                            }
                            brk = true;
                        }
                        if (!brk) {
                            prv.push(timesU);
                        }
                    }
                });
            }
            this.timesEnd = prv;
        },
        confirm_reserve() {
            if (!this.authUser) {
                return false;
            }

            this.send = true;
            let urlImg = require('@/assets/img/signo.png');
            let datInit = this.moment(this.reserve.date[0]).format('dddd') + ' ' + this.moment(this.reserve.date[0]).format('LL');
            let datEnd = this.moment(this.reserve.date[1]).format('dddd') + ' ' + this.moment(this.reserve.date[1]).format('LL');
            let dateHuman = datInit;
            if (datInit != datEnd) {
                dateHuman += ' <br> ' + this.moment(this.reserve.date[1]).format('dddd') + ' ' + this.moment(this.reserve.date[1]).format('LL');
            }
            this.$swal({
                html:
                    `
                    <img class="img-fluid" src="${urlImg}" style="max-width: 240px;;">
                    <h3>Revisa tus datos:</h3>
                    <hr>
                    <p class="text-justify">Antes de realizar tu reservación es necesario que revises y confirmes que los datos son correctos, de ser así haz clic en el botón de <strong class="strongM">Confirmar reservación</strong>.</p>
                    <hr>
                    <div class="row mt-2" style="width:100%">
                        <div class="col-12 col-md-12">
                            <p><strong class="title_m">Fecha:</strong> <br> ${dateHuman}</p>
                        </div>
                        <div class="col-12 col-md-4">
                            <p><strong class="title_m">N. asistentes:</strong> <br> ${this.reserve.participants}</p>
                        </div>
                        <div class="col-12 col-md-4">
                            <p><strong class="title_m">Hora entrada:</strong> <br> ${this.formatHours(this.reserve.time_start)}</p>
                        </div>
                        <div class="col-12 col-md-4">
                            <p><strong class="title_m">Hora salida:</strong> <br> ${this.formatHours(this.reserve.time_end)}</p>
                        </div>
                    </div>`,
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#5c5c5c',
                confirmButtonText: 'Confirmar reservación',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result) {
                        try {
                            let _endpoint = '/booking/save';
                            let service = request;
                            let reserve = JSON.parse(JSON.stringify(this.reserve));
                            console.log(reserve);
                            reserve.date = moment(this.reserve.date[0], 'DD-MM-YYYY').format('YYYY-MM-DD');
                            reserve.date_departure = moment(this.reserve.date[1], 'DD-MM-YYYY').format('YYYY-MM-DD');
                            reserve.course_id = reserve.course.id;
                            reserve.udn_id = reserve.udn.id;
                            reserve.time_end = reserve.time_end + ':00';
                            // let dateTime = new moment(reserve.date+' '+reserve.time_end+':00').subtract(1, 'seconds').format('hh:mm:ss');
                            // reserve.time_end = dateTime;
                            reserve.time_start = reserve.time_start + ':01';

                            let { data } = await service.post(_endpoint, reserve);
                            if (data) {

                                if (data.message == "Sala no disponible") {
                                    this.$swal({
                                        html:
                                            '<div class="confirmation">' +
                                            '<img class="mt-4" width="180" src="' + require('@/assets/img/logo_rojo.png') + '"> <br> ' +
                                            '<img width="180" class="mt-4" src="' + require('@/assets/img/error.png') + '"> <br> ' +
                                            '<h3 class="mt-4">La sala ya está reservada.</h3>' +
                                            '<p class="notifyModal"> La sala ya se encuentra reservada para la fecha seleccionada. Por favor, intenta con otra fecha disponible. </p>' +
                                            '</div>',
                                        background: '#f9f9f9',
                                        confirmButtonColor: '#da281b',
                                        confirmButtonText: 'Regresar',
                                    });

                                    this.send = false;
                                    return false;
                                }

                                this.$swal({
                                    html:
                                        '<div class="confirmation">' +
                                        '<img class="mt-4" width="180" src="' + require('@/assets/img/logo_rojo.png') + '"> <br> ' +
                                        '<img width="180" class="mt-4" src="' + require('@/assets/img/check.png') + '"> <br> ' +
                                        '<h3 class="mt-4">Confirmación exitosa</h3>' +
                                        '<p class="notifyModal">Tu <strong># de reserva es</strong> <strong class="codeModal">' + data.data[0].reservation_number + '</strong> guardarlo y compártelo con tus colaboradores para que se registren en el módulo de asistencia. </p>' +
                                        '</div>' +
                                        // '<button class="shareBtn">Compartir en WhatsApp</button>',
                                        ' <button class="btn btn-wath shareBtn mt-3 pr-3"><i class="fa-brands fa-whatsapp  mr-1 ml-1"></i>Compartir</button>',
                                    background: '#f9f9f9',
                                    confirmButtonColor: '#da281b',
                                    confirmButtonText: 'Cerrar',

                                });
                                this.send = false;
                                localStorage.removeItem('filter_data');
                                localStorage.removeItem('reserve_data');
                                // location.reload();
                                Event.$emit('event-close-booking-update');
                                Event.$emit('data-list-booking');
                                const shareBtn = this.$swal.getPopup().querySelector('.shareBtn');
                                shareBtn.addEventListener('click', () => {
                                    this.captureAndShare(data.data[0]);
                                });
                            }
                        } catch (error) {
                            this.errors = await this.errorNotification(error)
                            this.send = false;
                        }
                    }
                } else {
                    this.send = false;
                }
            });
            // this.$swal({
            //     html:
            //         '<img class="mt-4" width="180" src="' + require('@/assets/img/logo_rojo.png') + '"> <br> ' +
            //         '<img width="180" class="mt-4" src="' + require('@/assets/img/success.png') + '"> <br> ' +
            //         '<h3 class="mt-4">Confirmación exitosa</h3>',
            //     background: '#f9f9f9',
            //     confirmButtonColor: '#da281b',

            // }).then(async result => {
            //    
            // });
        },
        async captureAndShare(data) {
            // Captura el contenido del modal
            /* const modalContent = this.$swal.getPopup().querySelector('.confirmation');
            const canvas = await html2canvas(modalContent);
        
            // Obtiene la URL de la imagen capturada
            const imageUrl = canvas.toDataURL();
            console.log('imagen: ', imageUrl); */
            //const encodedImage = encodeURIComponent(imageUrl);
            const _data = data;

            console.log(_data);

            const _text = `_Hola, estimado colaborador._\n\nPara registrarte en el Módulo de Asistencia de tu curso: \n\n*${data.course ? data.course.name : ''}*\n*Centro de Experiencia:* ${data.room.name} \n*Día:* ${moment(data.date).format('LL')} \n*Horario:* ${moment(data.time_start, 'HH:mm').format('h:mm A')} a ${moment(data.time_end, 'HH:mm').format('h:mm A')}\n\nUtiliza el siguiente código: *_${data.reservation_number}_*\n\n¡Nos vemos pronto!`;

            const encodeText = encodeURIComponent(_text);

            // Construye el enlace de intercambio de WhatsApp
            const whatsappLink = `https://api.whatsapp.com/send?text=${encodeText}`;

            // Abre la ventana de WhatsApp con el enlace de intercambio
            window.open(whatsappLink, '_blank');
        },
        catNEvent() {
            this.reserve.course = this.course;
            this.reserve.udn = this.udn;
            let newValue = this.reserve;
            let info = newValue;
            info.office_id = this.reserve.room_id;
            localStorage.setItem('reserve_data', JSON.stringify(newValue));
        },
        showCondition() {
            this.catNEvent();
            this.$modal.show('ModalText');
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
        async fillData(newData){
            const vm = this;
            console.log(newData);
            vm.reserve.room_id = newData.room_id;
            vm.reserve.id = newData.id;
            await vm.get_office();
            vm.reserve.date = newData.date;
            vm.reserve.participants = newData.participants;
            vm.reserve.date = [vm.moment(newData.date).toDate(), vm.moment(newData.date_departure).toDate()];
            vm.reserve.time_start = newData.time_start.slice(0,5);
            await this.showTime(vm.reserve.date);
            await this.catchRange(vm.reserve.time_start);
            vm.reserve.time_end = newData.time_end.slice(0,5);
            vm.reserve.topics = newData.topic;
            vm.reserve.course_other = newData.course_other;
            vm.reserve.course_id = newData.course_id;
            vm.course = newData.course;
            vm.reserve.cofeeebreak = newData.coffee_break;
            // await this.catchParameters();
        },
    },
    async mounted() {
        // this.setHeaderTitle(`Salas de Experiencia`);
        await this.catalogue.get_udns();
        await this.catalogue.get_courses();
    },
    watch: {
        current_model: {
            handler(nVal) {
                this.fillData(nVal);
            },
            deep: true,
            immediate: true
        },
        'reserve.time_start': function (newValue) {
            // this.reserve.time_end = '';
            this.catchRange(newValue);
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },

}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <!-- <loginModal /> -->
            <!-- Cards -->
            <div class="row">
                <div class="col-md-12">
                    <div classs="row">
                        <form @submit.prevent="confirm_reserve">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 mb-n3">
                                        <h3>Información de reserva</h3>
                                        <p>Selecciona una fecha para listar los horarios disponibles</p>
                                        <hr>
                                    </div>
                                    <div class="col-lg-12 col-12 p-1">
                                        <label class="mb-1">Fecha:</label>
                                        <!-- <datepicker :format="'dd-MM-yyyy'" @selected="showTime" input-class="form-control "
                                            :required="true" v-model="reserve.date" :disabled-dates="disbaledDays()"
                                            :language="es">
                                        </datepicker> -->
                                        <date-picker :range="true" @pick="showTime" placeholder="Buscar por fecha"
                                            v-model="reserve.date" :disabled-date="disabledRange"
                                            input-class="form-control  text-date" :formatter="momentFormat"
                                            :input-attr="{ required: 'true' }"></date-picker>
                                    </div>
                                    <div class="col-lg-6 col-12 p-1">
                                        <label class="mb-1">Hora entrada:</label>
                                        <select class="form-control " required name="time_star"
                                            v-model="reserve.time_start">
                                            <option :value="time" :hidden="statusHorario(time)"
                                                v-for="(time, index_time) in timesStars" :key="index_time">
                                                {{ formatHours(time) }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-12 p-1">
                                        <label class="mb-1">Hora salida:</label>
                                        <select class="form-control " required name="time_end" @change="catNEvent()"
                                            v-model="reserve.time_end">
                                            <option :value="time" v-for="(time, index_time) in timesEnd" :key="index_time">
                                                {{ formatHours(time) }} {{ difHours(time) }}
                                            </option>
                                        </select>
                                    </div>
                                    <!-- <div class="col-12 mt-2">
                                        <label class="form-label">Selecciona tu Unidad de Negocios</label>
                                        <multiselect v-if="!catalogue.udns_load" :class="''"
                                            selectLabel="Presiona para seleccionar" selectedLabel="Seleccionado"
                                            deselectLabel="Presiona para eliminar" v-model="udn" label="name" value="id"
                                            placeholder="Escribe para buscar" open-direction="bottom"
                                            :multiple="true"
                                            :options="catalogue.udns.data">
                                            <span slot="noResult">No se encontraron Unidades de negocios con este
                                                nombre.</span>
                                        </multiselect>
                                        <div class="row text-center" v-else>
                                            <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                                style="margin: 0 auto;"></i>
                                        </div>
                                    </div> -->
                                    <div class="col-12 mt-2">
                                        <label class="form-label">Selecciona tu Curso</label>
                                        <multiselect v-if="!catalogue.courses_load" :class="''"
                                            selectLabel="Presiona para seleccionar" selectedLabel="Seleccionado"
                                            deselectLabel="Presiona para eliminar" v-model="course" label="name" value="id"
                                            placeholder="Escribe para buscar" open-direction="bottom"
                                            :options="catalogue.courses.data">
                                            <span slot="noResult">No se encontraron Cursos con este nombre.</span>
                                        </multiselect>
                                        <div class="row text-center" v-else>
                                            <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                                style="margin: 0 auto;"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12 p-1" v-if="course && course.active_input">
                                        <label class="mb-1">Escribe tu curso</label>
                                        <input class="form-control " name="course_other" v-model="reserve.course_other"
                                            required>
                                    </div>
                                    <div class="col-lg-12 col-12 p-1">
                                        <label class="mb-1">Temas a impartir</label>
                                        <input class="form-control " name="topics" v-model="reserve.topics" required>
                                    </div>
                                    <div class="col-lg-6 col-12 p-1">
                                        <label class="mb-1">No. Asistentes</label>
                                        <select class="form-control " name="asistent" @change="catNEvent()"
                                            v-model="reserve.participants" required>
                                            <option :value="n" v-for="(n, index_n) in loopAssistants()" :key="index_n">
                                                {{ n }} - Asistente<span v-if="n > 1">s</span>
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-12 p-1">
                                        <label class="mb-1">Inv. coffee break</label>
                                        <money class="form-control " v-model="reserve.cofeeebreak"></money>
                                    </div>


                                    <div class="col-12 p-2 mt-2">
                                        <label class="w-10 d-flex" @click="showCondition()">
                                            <input type="checkbox" class="form-control  form-control -sm" value="1"
                                                style="width: 30px;" required>
                                            <a>
                                                <h6 class="ml-3 mt-2">Términos y condiciones de reserva</h6>
                                            </a>
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-12 text-center pt-3">
                                <button v-if="authUser" class="btn btn-danger br20 shaw20 btn-block" :disabled="send"
                                    style="border-radius: 30px;">
                                    <i v-if="send" class="fa-solid fa-circle-notch fa-spin mr-2"></i>
                                    <span v-if="send">Enviando</span>
                                    <span v-else> Confirmar reservación</span>
                                </button>
                                <button type="button" @click="confirm_reserve()" v-else
                                    class="btn br20 shaw20 btn-danger btn-block" style="border-radius: 30px;">
                                    <span>Editar reservación</span>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 150px;
}

.rounded-lg {
    border-radius: 20px !important;
}

.hr_head {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 42px;
    position: relative;
}

.hr_footer {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 282px;
    position: relative;
}

.shadow_cal {
    box-shadow: rgba(136, 165, 191, 0.48) 5px 6px 6px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.swal2-icon.swal2-success.swal2-icon-show {
    margin-top: 120px !important;
}

.img.swal2-image {
    position: absolute;
    left: 32% !important;
}
</style>
