<script>
import Catalogue from '@/services/catalogue';
import Multiselect from 'vue-multiselect'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';

export default {
    components: {
        Multiselect,
        DatePicker
    },
    data() {
        return {
            catalogue: new Catalogue(),
            momentFormat: {
                stringify: (date) => {
                    return date ? this.moment(date).format('dddd') + ' ' + this.moment(date).format('LL') : ''
                },
                parse: (value) => {
                    console.log(value);
                    return value ? this.moment(value, 'DD-MM-YYYY').toDate() : ''
                }
            },
            search: {
                name: '',
                type_room_id: 1,
                date: '',
                time_start: '',
                time_end: '',
                assistants: '',
                territory_id: '',
                division_id: '',
                region_id: ''
            },
            territory: '',
            division: '',
            region: '',
            num_assistants: [],
            state: {
                disabledDates: {
                    to: new Date(),
                }
            },
            // MIN -  MAX
            min_assistants: 0,
            max_assistants: 0,
            min_hours: 0,
            max_hours: 0,
            load: false,
            timesStars: '',
            timesEnd: '',
        }
    },
    methods: {
        disabledRange: function (date) {
            return date <= this.moment().subtract(1, 'd');
        },
        getTimes(start = '09:00:00', end = '21:00:00', returnA = false) {
            let timesInBetween = [];
            let startH = parseInt(start.split(":")[0]);
            let startM = parseInt(start.split(":")[1]);
            let endH = parseInt(end.split(":")[0]);
            let endM = parseInt(end.split(":")[1]);

            if (startM == 30)
                startH++;
            for (let i = startH; i < endH; i++) {
                timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
                timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
            }
            timesInBetween.push(endH < 10 ? "0" + endH + ":00" : endH + ":00");
            if (endM == 30)
                timesInBetween.push(endH < 10 ? "0" + endH + ":30" : endH + ":30");

            if (returnA) {
                return timesInBetween;
            } else {
                timesInBetween.pop();
                this.timesStars = timesInBetween;
            }
        },
        catchRange(time) {
            let hours = this.getTimes(this.min_hours, this.max_hours, true);
            console.log(time);
            console.log(hours);
            let prv = [];
            if (hours) {
                hours.forEach(timesU => {
                    if (time <= timesU) {
                        prv.push(timesU);
                    }
                });
            }
            this.timesEnd = prv;
        },
        formatHours(time) {
            return moment(time, "HHmm").format("hh:mm a");
        },
        difHours(time) {
            if (this.search.time_start) {
                var hora1 = time.split(":"),
                    hora2 = this.search.time_start.split(":"),
                    t1 = new Date(),
                    t2 = new Date();

                t1.setHours(hora1[0], hora1[1]);
                t2.setHours(hora2[0], hora2[1]);

                //Aquí hago la resta
                t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes(), t1.getSeconds() - t2.getSeconds());

                let h = (t1.getHours() ? t1.getHours() + (t1.getHours() > 1 ? " hrs" : " hr ") : "");
                let m = (t1.getMinutes() ? t1.getMinutes() + (t1.getMinutes() > 1 ? " min" : " min") : "");

                let text = '';
                if (h != "") {
                    text += h;
                    if (m != "") {
                        text += ' y ';
                    }
                }

                if (m != "") {
                    text += m;
                }

                if (text == '') {
                    text = '0 min'
                }

                return ' - (' + text + ')';
            } else {
                return '';
            }
        },
        async loopAssistants() {
            if (this.catalogue.min_max_assistants) {
                let n = [];
                let min = this.catalogue.min_max_assistants[0].data.min_assistants;
                let max = this.catalogue.min_max_assistants[0].data.max_assistants;

                for (let index = min; index <= max; index++) {
                    n.push(index);
                }

                this.num_assistants = n;
            }

        },
        limitTextTerritorio(count) {
            return `y ${count} otros territorios`
        },
        asyncFindTerritorio(query) {
            try {
                let id = this.division?.id;
                this.catalogue.get_territories(query, id);
            } catch (error) {
                console.log(error);
            }
        },
        limitTextDivision(count) {
            return `y ${count} otras divisiones`
        },
        asyncFindDivision(query) {
            try {
                this.catalogue.get_divisions(query);
            } catch (error) {
                console.log(error);
            }
        },
        limitTextRegion(count) {
            return `y ${count} otros Regiones`
        },
        asyncFindRegion(query = '') {
            try {
                let id = this.territory?.id;
                this.catalogue.get_regions(query, id);
            } catch (error) {
                console.log(error);
            }
        },
        setTypeRoom(value) {
            console.log(value);
            this.search.type_room_id = value;
        },
        sendForm() {
            let search = this.search;
            localStorage.setItem('filter_data', JSON.stringify(search));
            this.$router.push({ name: 'list' })
        },
        loadSerach() {
            this.load = true;
            this.search.region_id = '';
            let vm = this;
            setTimeout(function () {
                vm.load = false;
            }, 900);
        },
    },
    watch: {
        'search.time_start'(newValue) {
            if (newValue > this.search.time_end) {
                this.search.time_end = "";
            }
            this.catchRange(newValue);
        },
        territory(newValue) {
            if (newValue) {
                this.asyncFindRegion();
                this.region = '';
                this.search.territory_id = newValue.id;
            }else{
                this.search.territory_id = '';
            }
        },
        division(newValue) {
            if (newValue) {
                this.asyncFindTerritorio();
                this.territory = '';
                this.search.division_id = newValue.id;
            }
        },
        region(newValue) {
            if (newValue) {
                this.search.region_id = newValue.id;
            } else {
                this.search.region_id = '';
            }
        },
        'search.assistants'(newValue) {
            let max = this.catalogue.min_max_assistants[0].data.max_assistants;
            if (max < newValue) {
                this.search.assistants = max;
            }
        },
        'search.type_room_id': function () {
            this.loadSerach();
        }
    },
    async mounted() {
        await this.catalogue.get_type_rooms();
        await this.catalogue.get_min_max_assistants();
        await this.catalogue.get_min_max_hours();
        await this.catalogue.get_divisions();
        await this.catalogue.get_territories();
        await this.catalogue.get_regions();
        await this.loopAssistants();

        this.min_assistants = this.catalogue.min_max_assistants[0].data.min_assistants;
        this.max_assistants = this.catalogue.min_max_assistants[0].data.max_assistants;
        this.min_hours = this.catalogue.min_max_hours[0].data.min_time;
        this.max_hours = this.catalogue.min_max_hours[0].data.max_time;
        this.getTimes(this.min_hours, this.max_hours);
        this.catchRange(this.search.time_start);
    }
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <ul class="nav nav-pills nav-tabs" id="custom-content-below-tab" role="tablist">
                <li class="nav-item" v-for="(type, index) in catalogue.type_rooms.data" :key="index">
                    <a class="nav-link" data-toggle="pill" :aria-selected="type.id == search.type_room_id ? true : false"
                        href="#" role="tab" :class="type.id == search.type_room_id ? 'active' : ''"
                        @click="setTypeRoom(type.id)">{{ type.name }}</a>
                </li>

            </ul>
        </div>

        <div class="col-12">
            <div class="card card-primary card-tabs" style="border-radius: 20px 20px 20px 20px; margin-top: -13px;">
                <div class="card-body" v-if="load">
                    <div class="row">
                        <div class="col-12 text-center p-5">
                            <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                            <h4 class="mt-3">Cargando</h4>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-else>
                    <div class="col-12">
                        <label class="form-label">Búsqueda</label>
                        <br>
                        <div class="input-group">
                            <input class="form-control border border-start-0" v-tooltip="'Escribe parte del nombre o dirección del ' + (search.type_room_id == 1 ? 'centro de experiencia ' : 'cuartel')  + ' y luego haz clic en el botón Buscar Sala'" type="search" v-model="search.name" :placeholder="(search.type_room_id == 1 ? 'Buscar centro de experiencia ' : 'Buscar cuartel ') + 'por nombre, dirección' ">
                            <div class="input-group-append">
                                <span class="input-group-text bg-light"  v-tooltip="'Escribe parte del nombre o dirección del ' + (search.type_room_id == 1 ? 'centro de experiencia ' : 'cuartel')  + ' y luego haz clic en el botón Buscar Sala'">
                                    <i class="fas fa-question-circle text-danger"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <label class="form-label">¿Qué día necesitas reservar tu espacio?</label>
                        <br>
                        <date-picker placeholder="Buscar por fecha" :range="true" v-model="search.date"
                            :formatter="momentFormat" :disabled-date="disabledRange"
                            input-class="form-control text-date"></date-picker>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <label class="form-label">Hora de entrada</label>
                                <select class="form-control" name="time_star" v-model="search.time_start">
                                    <option value="">Sin hora de entrada</option>
                                    <option :value="time" v-for="(time, index_time) in timesStars" :key="index_time">
                                        {{ formatHours(time) }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="form-label">Hora de salida</label>
                                <select class="form-control" name="time_end" v-model="search.time_end">
                                    <option value="">Sin hora de salida</option>
                                    <option :value="time" v-for="(time, index_time) in timesEnd" :key="index_time">
                                        {{ formatHours(time) }} {{ difHours(time) }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="form-label">No. de asistentes</label>
                                <input v-if="!catalogue.min_max_assistants_load" type="number" class="form-control"
                                    v-model="search.assistants" :min="min_assistants" :max="max_assistants">
                                <div class="row text-center" v-else>
                                    <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                        style="margin: 0 auto;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 mt-3" :class="search.type_room_id == 1 ? 'col-md-6' : 'col-md-12'">
                                <label class="form-label">Selecciona tu división</label>
                                <multiselect v-if="!catalogue.divisions_load" selectLabel="Presiona para seleccionar"
                                    selectedLabel="Seleccionado" deselectLabel="Presiona para eliminar" v-model="division"
                                    label="name" value="id" placeholder="Escribe para buscar" open-direction="bottom"
                                    :options="catalogue.divisions.data">
                                    <span slot="noResult">No se encontraron divisiones con este nombre.</span>
                                </multiselect>
                                <div class="row text-center" v-else>
                                    <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                        style="margin: 0 auto;"></i>
                                </div>
                            </div>
                            <div class="col-12 mt-3" :class="search.type_room_id == 1 ? 'col-md-6' : 'col-md-12'">
                                <label class="form-label">Selecciona tu territorio</label>
                                <multiselect v-if="!catalogue.territories_load" selectLabel="Presiona para seleccionar"
                                    selectedLabel="Seleccionado" deselectLabel="Presiona para eliminar" v-model="territory"
                                    label="name" value="id" placeholder="Escribe para buscar" open-direction="bottom"
                                    :options="catalogue.territories.data">
                                    <span slot="noResult">No se encontraron territorios con este nombre.</span>
                                </multiselect>
                                <div class="row text-center" v-else>
                                    <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                        style="margin: 0 auto;"></i>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mt-3" v-if="search.type_room_id == 1">
                                <label class="form-label">Selecciona tu región</label>
                                <multiselect v-if="!catalogue.regions_load" selectLabel="Presiona para seleccionar"
                                    selectedLabel="Seleccionado" deselectLabel="Presiona para eliminar" v-model="region"
                                    label="name" value="id" placeholder="Escribe para buscar" open-direction="bottom"
                                    :options="catalogue.regions.data" :internal-search="true">
                                    <span slot="noResult">No se encontraron regiones con este nombre.</span>
                                    <span slot="noOptions">Sin regiones relacionadas.</span>
                                </multiselect>
                                <div class="row text-center" v-else>
                                    <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                        style="margin: 0 auto;"></i>
                                </div>
                            </div>

                            <div :class="search.type_room_id == 1 ? 'col-md-6 mt-4' : 'col-md-12'"
                                class="col-12 text-right">
                                <br>
                                <button @click="sendForm()" :class="search.type_room_id == 1 ? '' : 'mt-2'"
                                    class="btn  rounded-pill w-50 btn-danger shadow_btn">Buscar
                                    sala</button>
                            </div>


                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style scoped>
.card {
    border-radius: 10px;
}

.shad {
    box-shadow: rgba(0, 0, 0, 0.35) -2px 4px 11px 0px;
    ;
}

hr {
    border-top: 3px solid rgb(133 136 144);
}

.btn-group.btn-group-toggle.w-100.rounded-pill {
    background-color: #7f8081;
}

.btn-secondary {
    background-color: #7f8081;
    border: #7f8081;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #fff;
    border: 1px solid #7f8081;
    border-radius: 30px !important;
    color: #000;
}

.nav-tabs .nav-link.active {
    background-color: #dc3545 !important;
}

@media (max-width: 450px) {

    .text-repo {
        display: block;
        margin-top: 10px;
        text-align: center;
    }
}

div.btn-group-toggle>label:first-child {
    border-radius: 30px 0px 0px 30px;
}

div.btn-group-toggle>label:last-child {
    border-radius: 0px 30px 30px 0px;
}

.nav-tabs .nav-link {
    background: #fff;
    color: #000;
}

.nav-tabs {
    border-bottom: 0px;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.nav-link {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    border-radius: 20px 20px 0px 0px !important;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

</style>