<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Catalogue from '@/services/catalogue';
import request from '../services/request'
import ModalMapComponent from '@/components/ModalMapComponent.vue';
import BookingModalComponent from '@/components/BookingModalComponent.vue';
import BookingUpdateModalComponent from '@/components/BookingUpdateModalComponent.vue';
import ModalMediaZoom from '@/components/ModalMediaZoom.vue';
import InventoryFinishedModalComponent from '@/components/InventoryFinishedModalComponent.vue';

export default {
    components: {
        ModalMapComponent,
        BookingModalComponent,
        BookingUpdateModalComponent,
        ModalMediaZoom,
        InventoryFinishedModalComponent
    },
    data() {
        return {
            catalogue: new Catalogue(),
            baseFiles: this.$BaseUrlFiles,
            territory: '',
            region: '',
            lists: {
                data: []
            },
            listsPass: {
                data: []
            },
            listsCancel: {
                data: []
            },
            favorites: [],
            errors: {},
            currentDate: new Date(),
            load: false,
            send_like: []
        }
    },
    methods: {
        zoomMedia(media) {
            // console.log('csadclmdsaclkdmoi8989');
            console.log(media);
            Event.$emit('event-zoom-media', media);
        },
        showBooking(booking) {
            // console.log('modal info');
            Event.$emit('event-show-booking', booking);
        },
        showBookingUpdate(booking) {
            // console.log('modal info');
            Event.$emit('event-show-booking-update', booking);
        },
        showMap(office) {
            console.log(office);
            Event.$emit('event-map-office', office);
        },
        async list() {
            this.load = true;
            let service = request;
            let _endpoint = '/booking/list?status=1';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.lists = data;
                    this.load = false;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
                this.load = false;

            }
        },
        async listPass() {
            let service = request;
            let _endpoint = '/booking/list?status=2';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.listsPass = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async listCancel() {
            let service = request;
            let _endpoint = '/booking/list?status=0';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.listsCancel = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        // cancelReservation(id) {
        //     let service = request;
        //     let vm = this;
        //     let _endpoint = `/booking/status/${id}?status=0`;
        //     this.$swal({
        //         title: 'Cancelar reservación',
        //         text: 'Se cancelara y estará disponible para otros usuario',
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonColor: '#d33',
        //         cancelButtonColor: '#5c5c5c',
        //         confirmButtonText: 'Ok',
        //         cancelButtonText: 'Cancelar'
        //     }).then(async (result) => {
        //         if (result.isConfirmed) {
        //             try {
        //                 let { data } = await service.get(_endpoint)
        //                 if (data) {
        //                     this.errors = await this.successNotification('Éxito al cancelar la reservación')
        //                     vm.list();
        //                 }
        //             } catch (error) {
        //                 this.errors = await this.errorNotification(error)
        //             }
        //         }
        //     })
        // },
        async cancelReservation(id) {
            let vm = this;
            const { value: text } = await this.$swal.fire({
                input: 'textarea',
                inputLabel: 'Describe el motivo de cancelación',
                allowOutsideClick: false,
                inputPlaceholder: 'Escribe el motivo de la cancelación ...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value !== '') {
                            resolve()
                        } else {
                            resolve('Es requerido el motivo de la cancelación')
                        }
                    })
                },
                preConfirm: async (text) => {
                    let service = request;
                    let form = {
                        reason: text,
                        status: 0,
                    }
                    let _endpoint = `/booking/status/${id}`;
                    try {
                        let { data } = await service.post(_endpoint, form)
                        if (data) {
                            vm.errors = await this.successNotification('Éxito al cancelar la reservación')
                            vm.list();
                        }
                    } catch (error) {
                        this.errors = await this.errorNotification(error)
                    }
                },
            })
            console.log(text);
        },
        async save_favorite(id) {
            let vm = this;
            this.send_like.push(id);
            try {
                let form = {
                    id: id
                };
                let _endpoint = '/favorite/save';
                let service = request;

                let { data } = await service.post(_endpoint, form)
                if (data) {
                    this.get_favorite();
                    if (data.type == 'deleted') {
                        this.errors = await this.successNotification('Éxito al eliminar de tus favoritos')
                    } else {
                        this.errors = await this.successNotification('Éxito al agregar a favoritos');
                    }
                    vm.send_like = vm.send_like.filter(function (v) { return v !== id })
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
                vm.send_like = vm.send_like.filter(function (v) { return v !== id })
            }
        },
        redirectOffice(id) {
            let search = this.search;
            localStorage.setItem('filter_data', JSON.stringify(search));
            this.$router.push({ path: '/office/' + id })
        },
        async get_favorite() {
            try {
                let _endpoint = '/favorite/list';
                let service = request;
                let { data } = await service.get(_endpoint)
                if (data) {
                    let ids = data.map(x => x['room_id']);
                    this.favorites = ids;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        exist_favorite(id) {
            console.log(id);
            console.log(this.favorites);
            let result = this.favorites.includes(id);     // true
            console.log(result);
            return result;
        },
        showInventory(booking = {}){
            Event.$emit('event-show-inventory', booking);
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
    },
    mounted() {
        this.setHeaderTitle('Mis Reservas');
        this.list();
        this.listPass();
        this.listCancel();
        if (this.authUser) {
            this.get_favorite();
        }
    },
    created(){
        Event.$on("data-list-booking", () => {
            this.list();
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },
}
</script>
<template>
    <div class="container pt-4">
        <div class="row">
            <BookingModalComponent />
            <BookingUpdateModalComponent />
            <ModalMapComponent />
            <ModalMediaZoom />
            <inventory-finished-modal-component />

            <div class="col-12 mt-3">
                <div class="card  br20 m-2 shaw20 mb-4">
                    <div class="card-body">
                        <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="pills-reserves-tab" data-toggle="pill" href="#pills-reserves"
                                    role="tab" aria-controls="pills-reserves" aria-selected="true">Activas</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-reserves-old-tab" data-toggle="pill"
                                    href="#pills-reserves-old" role="tab" aria-controls="pills-reserves-old"
                                    aria-selected="false">Finalizadas</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-reserves-cancel-tab" data-toggle="pill"
                                    href="#pills-reserves-cancel" role="tab" aria-controls="pills-reserves-cancel"
                                    aria-selected="false">Canceladas</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-12 text-center mt-5 mb-5" v-if="load">
                    <i class="fa-solid fa-circle-notch mb-3 fa-spin text-muted fa-5x"></i>
                    <h3 class="text-muted">Cargando</h3>
                </div>

                <div v-else class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-reserves" role="tabpanel"
                        aria-labelledby="pills-reserves-tab">
                        <div class="row" v-if="lists.data.length > 0">
                            <div v-for="(booking, index_booking) in lists.data" :key="index_booking"
                                class="col-md-6 col-lg-4 col-12">
                                <div class="card crd-sh br20 shaw20">
                                    <i v-if="send_like.includes(booking.room.id)" role="button"
                                        class="fa-lg fa-solid fa-circle-notch fa-spin btn-like"></i>
                                    <i v-if="authUser && !send_like.includes(booking.room.id)" role="button" class="fa-lg fa-solid fa-heart btn-like"
                                        @click="save_favorite(booking.room.id)"
                                        :class="exist_favorite(booking.room.id) ? 'text-danger' : 'text-muted'"></i>
                                    <img v-if="booking.room.medias.length > 0" role="button"
                                        @click="zoomMedia(booking.room.medias)"
                                        :src="`${baseFiles}${booking.room.medias[0].media.file_path}${booking.room.medias[0].media.file_name}`"
                                        class="card-img-top rounded-top bg-zoom-img" alt="photo">
                                    <img v-else src="@/assets/img/office/1.jpg" class="card-img-top rounded-top"
                                        alt="photo">
                                    <div class="card-body card-info-room">
                                        <div class="div_star">
                                            {{ booking.room.star }}
                                            <i class="fa-solid fa-star"
                                                :class="booking.room.star > 0 ? 'text-warning' : 'text-muted'"></i>
                                        </div>
                                        <h4 role="button" @click="redirectOffice(booking.room.id)" class="card-title tit">{{
                                            booking.room.name }}</h4>
                                        <p class="card-text ml-n2 mt-4 card_address text-muted col-10"
                                            style="font-size: 12px;">
                                            <a @click="showMap(booking.room)" class="text-muted uppercase">
                                                {{ booking.room.address }}
                                            </a>
                                        </p>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <!-- <div class="row">
                                                    <div class="col-12">
                                                        <h5 class="text-success mb-2">Reservado</h5>
                                                    </div>
                                                    <div class="col-12">
                                                        <h6 class="text-danger" v-if="booking.reservation_number">#
                                                            Reserva: {{ booking.reservation_number }}</h6>
                                                    </div>
                                                </div> -->
                                                <div class="row">
                                                    <!-- <div class="col-7">
                                                    </div>
                                                    <div class="col-7">
                                                    </div> -->
                                                    <div class="col-7 col-md-8">
                                                        <h5 class="text-success mb-2">Reservado</h5>
                                                        <h6 class="text-danger" v-if="booking.reservation_number">#
                                                            Reserva: {{ booking.reservation_number }}</h6>
                                                        <p style="font-size: 13px;" class="mt-n1 mb-1">
                                                            <strong>Fecha:</strong><br>
                                                            {{ moment(booking.date).format('DD/MM/YYYY') }}
                                                            <span v-if="booking.date_departure">
                                                                <span v-if="booking.date != booking.date_departure">
                                                                    a {{ moment(booking.date_departure).format('DD/MM/YYYY') }}
                                                                </span>
                                                            </span>
                                                        </p><br>
                                                        <p style="font-size: 13px;" class="mt-n4">
                                                            <strong>Horario:</strong><br> {{
                                                                booking.time_start.substring(0, 5) }}
                                                            Hrs a {{ booking.time_end.substring(0, 5) }} Hrs
                                                        </p>
                                                    </div>
                                                    <div class="col-4">
                                                        <button type="button" class="btn btn-primary rounded-r2 btn-sm mt-0 mb-1" @click="showBookingUpdate(booking)">
                                                            <i class="fa fa-pen"></i> Editar
                                                        </button>
                                                        <button @click="showBooking(booking)"
                                                            class="btn btn-danger btn-sm rounded-r2 mt-0 mb-1">Ver más</button>
                                                        <button @click="cancelReservation(booking.id)"
                                                        class="btn btn-secondary btn-sm rounded-r2 mb-1">Cancelar</button>
                                                        <button @click="showInventory(booking)"
                                                            class="btn btn-success btn-sm rounded-r2">Finalizar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row justify-content-center">
                            <div class="col-7 text-center">
                                <div class="card br20 shaw20">
                                    <div class="card-body p-5">
                                        <i class="fa-solid fa-magnifying-glass-location fa-5x text-danger"></i>
                                        <H3 class="mt-3">Sin reservas existentes</H3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-reserves-old" role="tabpanel"
                        aria-labelledby="pills-reserves-old-tab">
                        <div class="row" v-if="listsPass.data.length > 0">
                            <div v-for="(booking, index_like) in listsPass.data" :key="index_like"
                                class="col-md-6 col-lg-4 col-12">
                                <div class="card br20 shaw20">
                                    <i v-if="send_like.includes(booking.room.id)" role="button"
                                        class="fa-lg fa-solid fa-circle-notch fa-spin btn-like"></i>
                                    <i v-if="authUser && !send_like.includes(booking.room.id)" role="button" class="fa-lg fa-solid fa-heart btn-like"
                                        @click="save_favorite(booking.room.id)"
                                        :class="exist_favorite(booking.room.id) ? 'text-danger' : 'text-muted'"></i>
                                    <img v-if="booking.room.medias.length > 0" role="button"
                                        @click="zoomMedia(booking.room.medias)"
                                        :src="`${baseFiles}${booking.room.medias[0].media.file_path}${booking.room.medias[0].media.file_name}`"
                                        class="card-img-top rounded-top bg-zoom-img" alt="photo">
                                    <img v-else src="@/assets/img/office/1.jpg" class="card-img-top rounded-top"
                                        alt="photo">
                                    <div class="card-body card-info-room">
                                        <div class="div_star">
                                            {{ booking.room.star }}
                                            <i class="fa-solid fa-star"
                                                :class="booking.room.star > 0 ? 'text-warning' : 'text-muted'"></i>
                                        </div>
                                        <h4 role="button" @click="redirectOffice(booking.id)" class="card-title tit">{{
                                            booking.room.name }}</h4>
                                        <p class="card-text ml-n2 mt-4 card_address text-muted col-10"
                                            style="font-size: 12px;">
                                            <a @click="showMap(booking.room)" class="text-muted uppercase">
                                                {{ booking.room.address }}
                                            </a>
                                        </p>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <h5 class="text-info mb-2">Finalizada</h5>
                                                    </div>
                                                    <div class="col-7 text-right">
                                                        <h6 class="text-danger" v-if="booking.reservation_number">#
                                                            Reserva: {{ booking.reservation_number }}</h6>
                                                    </div>
                                                </div>
                                                <p style="font-size: 13px;" class="mt-n1 mb-1">
                                                    <strong>Fecha:</strong><br>
                                                    {{ moment(booking.date).format('DD/MM/YYYY') }}
                                                    <span v-if="booking.date_departure">
                                                        <span v-if="booking.date != booking.date_departure">
                                                            a {{ moment(booking.date_departure).format('DD/MM/YYYY') }}
                                                        </span>
                                                    </span>

                                                </p><br>
                                                <p style="font-size: 13px;" class="mt-n4">
                                                    <strong>Horario:</strong><br> {{
                                                        booking.time_start.substring(0, 5) }}
                                                    Hrs a {{ booking.time_end.substring(0, 5) }} Hrs
                                                </p>
                                                <button @click="showBooking(booking)"
                                                    class="btn btn-danger btn-sm rounded-r mt-3 mb-3">Ver más</button>
                                                <router-link :to="{ path: 'reviews/' + booking.id }"
                                                    class="btn btn-danger rounded-r  btn-sm mt-5">Opinar</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row justify-content-center">
                            <div class="col-7 text-center">
                                <div class="card">
                                    <div class="card-body p-5">
                                        <i class="fa-solid fa-magnifying-glass-location fa-5x text-danger"></i>
                                        <H3 class="mt-3">Sin reservas existentes</H3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-reserves-cancel" role="tabpanel"
                        aria-labelledby="pills-reserves-cancel-tab">
                        <div class="row" v-if="listsCancel.data.length > 0">
                            <div v-for="(booking, index_like) in listsCancel.data" :key="index_like"
                                class="col-md-6 col-lg-4 col-12">
                                <div class="card br20 shaw20">
                                    <i v-if="send_like.includes(booking.room.id)" role="button"
                                        class="fa-lg fa-solid fa-circle-notch fa-spin btn-like"></i>
                                    <i v-if="authUser && !send_like.includes(booking.room.id)" role="button" class="fa-lg fa-solid fa-heart btn-like"
                                        @click="save_favorite(booking.room.id)"
                                        :class="exist_favorite(booking.room.id) ? 'text-danger' : 'text-muted'"></i>
                                    <img v-if="booking.room.medias.length > 0" role="button"
                                        @click="zoomMedia(booking.room.medias)"
                                        :src="`${baseFiles}${booking.room.medias[0].media.file_path}${booking.room.medias[0].media.file_name}`"
                                        class="card-img-top rounded-top bg-zoom-img" alt="photo">
                                    <img v-else src="@/assets/img/office/1.jpg" class="card-img-top rounded-top"
                                        alt="photo">
                                    <div class="card-body card-info-room">
                                        <div class="div_star">
                                            {{ booking.room.star }}
                                            <i class="fa-solid fa-star"
                                                :class="booking.room.star > 0 ? 'text-warning' : 'text-muted'"></i>
                                        </div>
                                        <h4 role="button" @click="redirectOffice(booking.id)" class="card-title tit">{{
                                            booking.room.name }}</h4>
                                        <p class="card-text ml-n2 mt-4 card_address text-muted col-10"
                                            style="font-size: 12px;">
                                            <a @click="showMap(booking.room)" class="text-muted uppercase">
                                                {{ booking.room.address }}
                                            </a>
                                        </p>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <h5 class="text-danger mb-2">Cancelada</h5>
                                                    </div>
                                                    <div class="col-7 text-right">
                                                        <h6 class="text-danger" v-if="booking.reservation_number">#
                                                            Reserva: {{ booking.reservation_number }}</h6>
                                                    </div>
                                                </div>
                                                <p style="font-size: 13px;" class="mt-n1 mb-1">
                                                    <strong>Fecha:</strong><br>
                                                    {{ moment(booking.date).format('DD/MM/YYYY') }}
                                                    <span v-if="booking.date_departure">
                                                        <span v-if="booking.date != booking.date_departure">
                                                            a {{ moment(booking.date_departure).format('DD/MM/YYYY') }}
                                                        </span>
                                                    </span>

                                                </p><br>
                                                <p style="font-size: 13px;" class="mt-n4">
                                                    <strong>Horario:</strong><br> {{
                                                        booking.time_start.substring(0, 5) }}
                                                    Hrs a {{ booking.time_end.substring(0, 5) }} Hrs
                                                </p>
                                                <button @click="showBooking(booking)"
                                                    class="btn btn-danger btn-sm rounded-r mt-3 mb-3">Ver más</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row justify-content-center">
                            <div class="col-7 text-center">
                                <div class="card">
                                    <div class="card-body p-5">
                                        <i class="fa-solid fa-magnifying-glass-location fa-5x text-danger"></i>
                                        <H3 class="mt-3">Sin reservas existentes</H3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12">
                <div class="row mt-4 pb-4">

                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.shw {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.crd-sh {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.div_icons {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 110px;
}
.rounded-r2 {
    border-radius: 20px;
    /*position: absolute;*/
    width: 110px;
}

@media (max-width: 546px) {
    .txt-h {
        display: none;
    }

    .icon_res {
        font-size: 20px;
    }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #636666;
    border-radius: 30px;
}</style>