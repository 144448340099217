import Vue from 'vue'

export const setUser = (state, payload) => {
    if(!payload) {
        localStorage.removeItem('user')
        Vue.set(state, 'user', null)
    } else {
        localStorage.setItem('user', JSON.stringify(payload))
        Vue.set(state, 'user', Object.assign({}, {...payload}))
    }
}

export const setToken = (state, payload) => {
    if(!payload) {
        localStorage.removeItem('access_token')
        Vue.set(state, 'access_token', null)
    } else {
        localStorage.setItem('access_token', payload)
        Vue.set(state, 'access_token', payload)
    }
}
