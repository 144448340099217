<script>
import carousel from 'vue-owl-carousel'
export default {
    components: {
        carousel,
    }    
}
</script>
<template>
    <modal name="ModalText" :width="'95%'" :maxWidth="900" :adaptive="true" :shiftY="0.2">
        <div class="row">           
            <div class="col-12 pl-5 pr-5 pb-5 pt-4">
                <div class="row">
                    <div class="col-12 text-center">
                        <h4>Términos y condiciones de reserva</h4>
                        <hr>
                    </div>                                                
                    <div class="col-12">                                                
                        <carousel :autoplay="false" :nav="true" :loop="false" :items="1">
                            <img src="@/assets/img/lineamiento_new/1.png" class="img-fluid">
                            <img src="@/assets/img/lineamiento_new/2.png" class="img-fluid">
                            <img src="@/assets/img/lineamiento_new/3.png" class="img-fluid">
                            <img src="@/assets/img/lineamiento_new/4.png" class="img-fluid">
                            <img src="@/assets/img/lineamiento_new/5.png" class="img-fluid">
                            <img src="@/assets/img/lineamiento_new/6.png" class="img-fluid">
                        </carousel>
                    </div>                    
                </div>
            </div>
        </div>
    </modal>
</template>
<style>
.head-modal {
    background-image: url('@/assets/img/fondo_01.jpg');
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>