import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import status from './status'
import { alert } from './alert';

Vue.use(Vuex)

import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 6000
  }
}
Vue.use(Snotify, options)

export default new Vuex.Store({
  modules: {
    auth,
    status,
    alert,
  },
})
